import {
  Button, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow,
} from '@nextui-org/react';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import api from '../api';
import getDate from '../helpers/getDate';
import priceWithCurrency from '../helpers/priceWithCurrency';
import { useHttp } from '../contexts/http';
import useMonthAndYear from '../hooks/useMonthAndYear';

function CategoryTransactionsTable({
  categoryId,
  // onOpenEditTransactionModal
}: {
  categoryId: string
  // onOpenEditTransactionModal: (id: string) => void
}) {
  const http = useHttp();

  const {
    currentMonth, currentYear,
  } = useMonthAndYear();

  const query = useInfiniteQuery({
    initialPageParam: null as string | null,
    queryKey: ['transaction.month-infinite'],
    queryFn: (props: any) => {
      const { pageParam: cursor } = props as unknown as { pageParam: string | null };
      return api.transaction.list({
        http,
        cursor: cursor || null,
        categoryId,
      });
    },
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    // getPreviousPageParam: (firstPage, allPages) => firstPage.prevCursor,
  });

  useEffect(() => {
    query.refetch();
  }, [currentMonth, currentYear, categoryId, query]);

  const transactionsAndTransfers = query.data;

  const categoriesQuery = useQuery({ queryKey: ['category.list'], queryFn: () => api.category.list(http) });
  const accountsQuery = useQuery({ queryKey: ['account.list'], queryFn: () => api.account.list(http) });

  const categories = categoriesQuery.data;
  const accounts = accountsQuery.data;

  if (!transactionsAndTransfers || !categories || !accounts) { // TODO: !transactionsStats.dailyExpenses ???
    return null;
  }

  const flat = transactionsAndTransfers.pages.map(({ transactions }: any) => transactions).flat();

  return (
    <div>
      <Table>
        <TableHeader>
          <TableColumn>TYPE</TableColumn>
          <TableColumn>AMOUNT</TableColumn>
          <TableColumn>ACCOUNT</TableColumn>
          <TableColumn>DATE</TableColumn>
          <TableColumn>COMMENT</TableColumn>
        </TableHeader>
        <TableBody>
          {flat.map((transactionOrTransfer) => {
            const transaction = transactionOrTransfer;
            const dateStr = getDate(transaction.transactionAt);

            return (
              <TableRow
                key={transaction.id}
                onClick={() => {
                  // onOpenEditTransactionModal(transaction.id)
                }}
              >
                <TableCell>
                  <span style={{
                    color: transaction.type === 'income' ? 'green' : 'red',
                  }}
                  >
                    {transaction.type}
                  </span>
                </TableCell>
                <TableCell>{priceWithCurrency(transaction.usdAmount)}</TableCell>
                <TableCell>{accounts.find((account) => account.id === transaction.accountId)?.title || 'N/A'}</TableCell>
                <TableCell>{dateStr}</TableCell>
                <TableCell>{transaction?.comment || 'N/A'}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {query.hasNextPage && (
        <Button
          onClick={() => {
            query.fetchNextPage();
          }}
          style={{
            display: 'block',
            margin: '20px auto',
            color: 'white',
          }}
        >
          Load more
        </Button>
      )}
    </div>
  );
}

export default CategoryTransactionsTable;
