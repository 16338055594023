import { useQuery } from '@tanstack/react-query';
import {
  Button,
  Card, CardBody, CardHeader,
} from '@nextui-org/react';
import { useState } from 'react';
import { MailPlusIcon } from 'lucide-react';
import api from '../api';
import { useHttp } from '../contexts/http';
import SegmentedBarChart from './SegmentedBarChart';
import getMonthNameByNumber from '../helpers/getMonthName';
import getNowDayMonthYear from '../helpers/getNowDayMonthYear';

function TransactionsStats() {
  const http = useHttp();
  const yearlyExpensesByCategoryQuery = useQuery({ queryKey: ['category.expenses.yearly'], queryFn: () => api.category.listExpenses(http) });
  const yearlyIncomesByCategoryQuery = useQuery({ queryKey: ['category.incomes.yearly'], queryFn: () => api.category.listIncomes(http) });

  const monthlyExpensesByCategoryQuery = useQuery({
    queryKey: ['category.expenses.monthly'],
    queryFn: () => api.category.listExpenses(http, {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    }),
  });

  const monthlyIncomesByCategoryQuery = useQuery({
    queryKey: ['category.incomes.monthly'],
    queryFn: () => api.category.listIncomes(http, {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    }),
  });

  const transactionsStatsQuery = useQuery({ queryKey: ['transaction.stats'], queryFn: () => api.transaction.stats(http) });

  const getExpensesYearlyData = () => {
    if (yearlyExpensesByCategoryQuery.data) {
      return yearlyExpensesByCategoryQuery.data.map(({ sumUsdAmount, title }: any) => ({
        id: title,
        label: title,
        value: sumUsdAmount,
        color: 'hsl(197, 70%, 50%)',
      }));
    }

    return [];
  };

  const getExpensesMonthlyData = () => {
    if (monthlyExpensesByCategoryQuery.data) {
      return monthlyExpensesByCategoryQuery.data.map(({ sumUsdAmount, title }: any) => ({
        id: title,
        label: title,
        value: sumUsdAmount,
        color: 'hsl(197, 70%, 50%)',
      }));
    }

    return [];
  };

  const getIncomesYearlyData = () => {
    if (yearlyIncomesByCategoryQuery.data) {
      return yearlyIncomesByCategoryQuery.data.map(({ sumUsdAmount, title }: any) => ({
        id: title,
        label: title,
        value: sumUsdAmount,
        color: 'hsl(197, 70%, 50%)',
      }));
    }

    return [];
  };

  const getIncomesMonthlyData = () => {
    if (monthlyIncomesByCategoryQuery.data) {
      return monthlyIncomesByCategoryQuery.data.map(({ sumUsdAmount, title }: any) => ({
        id: title,
        label: title,
        value: sumUsdAmount,
        color: 'hsl(197, 70%, 50%)',
      }));
    }

    return [];
  };

  const currentMonthIncomes = transactionsStatsQuery.data?.currentMonthIncomes.amount || 0;
  const currentMonthExpenses = transactionsStatsQuery.data?.currentMonthExpenses.amount || 0;

  const currentYearIncomes = transactionsStatsQuery.data?.currentYearIncomes.amount || 0;
  const currentYearExpenses = transactionsStatsQuery.data?.currentYearExpenses.amount || 0;

  const [chartType] = useState('expenses');

  const now = getNowDayMonthYear();

  return (
    <div className="flex gap-8 flex-col">
      <Card className="bg-content1">
        <CardHeader className="text-center w-full bg-content2 p-4 p-2 pl-4 rounded-lg justify-between flex">
          <div className="flex align-center justify-center">
            <div className="flex text-center text-sm tracking-wider font-bold items-center justify-center">
              {now.year}
              {' '}
              By Categories
            </div>
          </div>
          <div>
            <Button className="tracking-wider" variant="ghost" isDisabled>
              <MailPlusIcon color="#FDBD1A" />
              {' '}
              Settings
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <div className="flex flex-wrap">
            <div
              style={{
                width: 300,
              }}
              className="m-auto"
            >
              <SegmentedBarChart
                label={chartType === 'expenses' ? 'Expense' : 'Income'}
                value={chartType === 'expenses' ? currentYearExpenses : currentYearIncomes}
                data={
                  chartType === 'expenses' ? getExpensesYearlyData() : getIncomesYearlyData()
                }
              />
            </div>
            <div
              style={{
                width: 300,
              }}
              className="m-auto"
            >
              <SegmentedBarChart
                label={!(chartType === 'expenses') ? 'Expense' : 'Income'}
                value={!(chartType === 'expenses') ? currentYearExpenses : currentYearIncomes}
                data={
                  !(chartType === 'expenses') ? getExpensesYearlyData() : getIncomesYearlyData()
                }
              />
            </div>
          </div>
        </CardBody>
      </Card>
      <Card className="bg-content1">
        <CardHeader className="text-center w-full bg-content2 p-4 p-2 pl-4 rounded-lg justify-between flex">
          <div className="flex align-center justify-center">
            <div className="flex text-center text-sm tracking-wider font-bold items-center justify-center">
              {getMonthNameByNumber(new Date().getMonth() + 1)}
              {' '}
              {now.year}
              {' '}
              By Categories
            </div>
          </div>
          <div>
            <Button className="tracking-wider" variant="ghost" isDisabled>
              <MailPlusIcon color="#FDBD1A" />
              {' '}
              Settings
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <div className="flex flex-wrap">
            <div
              style={{
                width: 300,
              }}
              className="m-auto"
            >
              <SegmentedBarChart
                label={chartType === 'expenses' ? 'Expense' : 'Income'}
                value={chartType === 'expenses' ? currentMonthExpenses : currentMonthIncomes}
                data={
                  chartType === 'expenses' ? getExpensesMonthlyData() : getIncomesMonthlyData()
                }
              />
            </div>
            <div
              style={{
                width: 300,
              }}
              className="m-auto"
            >
              <SegmentedBarChart
                label={!(chartType === 'expenses') ? 'Expense' : 'Income'}
                value={!(chartType === 'expenses') ? currentMonthExpenses : currentMonthIncomes}
                data={
                  !(chartType === 'expenses') ? getExpensesMonthlyData() : getIncomesMonthlyData()
                }
              />
            </div>
          </div>
        </CardBody>
      </Card>
      {/* <Card className="bg-content2">
        <CardHeader className="text-center w-full bg-content1 p-6">
          <div className="text-center w-full text-sm tracking-wider text-content4 font-light">
            2024
          </div>
        </CardHeader>
        <div
          style={{
            width: 300,
          }}
          className="m-auto"
        >
          <SegmentedBarChart data={getIncomesData()} />
        </div>
        <div className="flex gap-2 m-auto mb-8 pl-6 pr-6">
          <Button
            size="sm"
            color="primary"
            variant="solid"
            className="tracking-wider"
          >
            Expenses
          </Button>
          <Button
            size="sm"
            color="default"
            variant="ghost"
            className="tracking-wider"
          >
            Incomes
          </Button>
          <Button
            size="sm"
            color="default"
            variant="ghost"
            className="tracking-wider"
          >
            Ballance
          </Button>
        </div>
      </Card> */}
    </div>
  );
}

export default TransactionsStats;
