import React from 'react';
import { Button } from '@nextui-org/react';

interface AccountButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  variant: any
}

const AccountButton: React.FC<AccountButtonProps> = ({
  onClick, children, variant,
}) => (
  <Button
    variant={variant}
    color="default"
    className="bg-content2 min-w-[200px]"
    style={{
      height: 'auto',
      padding: '10px 20px 15px 70px',
      flexDirection: 'column',
      // minWidth: '200px',
      alignItems: 'flex-start',
      fontSize: 20,
      border: 0,
    }}
    onClick={onClick}
  >
    {children}
  </Button>
);

export default AccountButton;
