import { ResponsivePie } from '@nivo/pie';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import priceWithCurrency from '../helpers/priceWithCurrency';
import api from '../api';
import { Category } from '../types';
import { useHttp } from '../contexts/http';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
function SegmentedBarChart({ data, value, label }: {
  data: any;
  value: number;
  label: string;
}) {
  const http = useHttp();
  const categoriesQuery = useQuery({ queryKey: ['category.list'], queryFn: () => api.category.list(http) });

  const tooltipText = document.documentElement.classList.contains('dark') ? 'white' : '#161618'; // TODO: refactor
  const tooltipBackground = document.documentElement.classList.contains('dark') ? '#161618' : 'white'; // TODO: refactor

  const navigate = useNavigate();

  return (
    <div style={{
      height: 300,
      width: '100%',
      position: 'relative',
    }}
    >
      <ResponsivePie
        colors={{ scheme: 'dark2' }}
        data={data}
        margin={{
          top: 20, right: 20, bottom: 20, left: 20,
        }}
        innerRadius={0.8}
        padAngle={2}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        arcLabel=""
        enableArcLinkLabels={false}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        theme={{
          tooltip: {
            container: {
              background: tooltipBackground,
              color: tooltipText,
              fontSize: '16px',
              padding: '5px 10px',
            },
          },
        }}
        onClick={({ id }) => {
          const category = categoriesQuery.data?.find((cat: Category) => cat.title === id);

          if (category) {
            navigate(`/category/${category.id}`);
          }
        }}
      />
      <div style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      >
        <div className={`font-bold tracking-wider ${String(value).length > 4 ? 'text-2xl' : 'text-4xl'}`}>{priceWithCurrency(value)}</div>
        <div className="text-sm tracking-wide font-bold text-sm tracking-wider text-content4 text-center mt-2 uppercase">{label}</div>
      </div>
    </div>
  );
}

export default SegmentedBarChart;
