import numberWithCommas from './numberWithCommas';

function getCurrency(price: string, currencyCode: string): string {
  const currencySymbols: { [key: string]: string } = {
    USD: '$', // US Dollar
    EUR: '€', // Euro
    GBP: '£', // British Pound
    JPY: '¥', // Japanese Yen
    CNY: '¥', // Chinese Yuan
    RSD: 'дин.', // Serbian Dinar
    RUB: '₽', // Russian Ruble
    TRY: '₺', // Turkish Lira
    CLP: 'CLP', // Chilean Peso
  };

  const currencyAfter: { [key: string]: boolean } = {
    RSD: true,
    RUB: true,
    CLP: true,
  };

  const symbol = currencySymbols[currencyCode];
  const after = currencyAfter[currencyCode];

  if (!symbol) {
    throw new Error(`Currency symbol for ${currencyCode} not found.`);
  }

  return `${!after ? symbol : ''}${numberWithCommas(Number(price))}${after ? ` ${symbol}` : ''}`;
}

export default getCurrency;
