import {
  BookCheckIcon, HomeIcon, ShirtIcon, WineIcon, PillIcon, DramaIcon, BookA, ScrollIcon, BeefIcon, StoreIcon, BookUser, Building2, BusIcon, ReceiptIcon, BrainCircuit,
} from 'lucide-react';

const Icon = {
  Default: BookCheckIcon,
  Home: HomeIcon,
  Clothes: ShirtIcon,
  Alcohol: WineIcon,
  Medicine: PillIcon,
  Entertainment: DramaIcon,
  Learning: BookA,
  Taxes: ScrollIcon,
  Restaurants: BeefIcon,
  Shop: StoreIcon,
  Family: BookUser,
  Rent: Building2,
  Transport: BusIcon,
  Paycheck: ReceiptIcon,
  Psycho: BrainCircuit,
};

export default Icon;
