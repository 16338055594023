// AccountButton.tsx
import React from 'react';
import { EditIcon } from 'lucide-react';
import CategoryIcon from './CategoryIcon';

interface CategoryButtonProps {
  title: string;
  icon?: string;
  onClick: () => void;
  onEditClick: () => void;
}

// const mapImage: any = {
//   'Home': '/categories/home.png',
//   'Restaurants': '/categories/restaurants.png',
//   'Clothes': '/categories/clothes.png',
//   'Alcohol': '/categories/alcohol.png',
//   'Medicine': '/categories/medicine.png',
//   'Entertainment': '/categories/entertainment.png',
//   'Studying': '/categories/studying.png',
//   'Taxes': '/categories/taxes.png'
// }

const CategoryButton: React.FC<CategoryButtonProps> = ({
  title, icon, onClick, onEditClick,
}) => (
  <div style={{
    display: 'flex',
    gap: 10,
  }}
  >
    <button
      type="button"
      onClick={onClick}
      style={{
        // position: 'relative',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        textAlign: 'left',
      }}
    >
      {/* <Button
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          zIndex: 10
        }}
        className="text-tiny text-white bg-black/20" variant="flat" color="default" radius="lg" size="sm" onClick={onClick}
      >
        Edit
      </Button> */}
      {/* <BookCheckIcon className="h-6 w-6 text-default-900 dark:text-default-900" /> */}
      <CategoryIcon icon={icon} />
      <div>
        {/* <p className='text-xs'>{description}</p>   */}
        <p
          className="text-sm text-default-900 dark:text-default-900"
        >
          {title}
        </p>
      </div>
    </button>
    <button
      type="button"
      onClick={onEditClick}
    >
      <EditIcon className="h-5 w-5 text-black dark:text-white" />
    </button>
  </div>
);

CategoryButton.defaultProps = {
  icon: 'default',
};

export default CategoryButton;
