import {
  Button, Card, CardBody, CardHeader,
} from '@nextui-org/react';
import { MailPlusIcon } from 'lucide-react';
import { Trans } from '@lingui/macro';
import { ChartBarIcon } from '@heroicons/react/24/solid';
import { useQuery } from '@tanstack/react-query';
import DashboardTitle from '../components/DashboardTitle';
import TransactionsStats from '../components/TransactionsStats';
import HeroWidget from '../components/HeroWidget';
import useQueryAccountsAndCategories from '../hooks/useQueryAccountsAndCategories';
import MainLayout, { useAuth } from '../layouts/MainLayout';
import BalanceChangeChart from '../components/BalanceChangeChart';
import { useHttp } from '../contexts/http';
import api from '../api';
import getDate from '../helpers/getDate';
import useModals from '../hooks/useModals';

function Index() {
  const { modalContext } = useModals();

  const { accountsQuery, categoriesQuery } = useQueryAccountsAndCategories();

  const hasAccountAndCategories = accountsQuery.data?.length && categoriesQuery.data?.length;

  const accounts = accountsQuery.data;

  const http = useHttp();

  const totalAccountTotalBalanceQuery = useQuery({ queryKey: ['account-balance.getTotalAccountBalance'], queryFn: () => api.accountBalance.getTotal(http) });
  const totalBalance = totalAccountTotalBalanceQuery.data;

  const totalNow = totalBalance ? totalBalance.filter((item) => accounts?.find((account) => account.id === item.id)?.type === 'internal').map(
    ({ usdBalance }: any) => usdBalance,
  ).reduce((acc: any, item: any) => Math.round(acc + Number(item)), 0) : 0;

  const computedAccountBalancesQuery = useQuery({ queryKey: ['computedAccountBalances.get'], queryFn: () => api.computedAccountBalance.get(http) });

  /* eslint-disable */
  const computedAccountBalances = computedAccountBalancesQuery?.data ? [...computedAccountBalancesQuery.data?.map((item: any) => (
    {
      x: getDate(item.date),
      y: item.computedBalance.filter(({ id }: any) => accounts?.find((account: any) => account.id === id)?.type === 'internal').reduce((acc: any, _item: any) => Math.round(acc + Number(_item.usdBalance)), 0),
    }
  )), {
    x: getDate(new Date()),
    y: totalNow,
  }] : [];
  /* eslint-enable */

  const { useAuthQuery } = useAuth();

  const authQuery = useAuthQuery();

  const username = authQuery?.data?.username;

  return (
    <MainLayout>
      <div className="flex flex-col md:flex-row gap-8">
        <div style={{
          minWidth: '240px',
        }}
        >
          <DashboardTitle />
        </div>
        <div className="pl-4 pr-4 md:pl-0 md:pr-0 w-full">
          <div className="mb-8">
            <TransactionsStats />
          </div>
          <Card>
            <CardHeader className="mb-6 bg-content2 rounded-xl md:p-2 md:pl-4 font-bold flex justify-between text-sm tracking-wider font-bold gap-2">
              <p className="flex flex-row gap-2 items-center">
                <ChartBarIcon color="#FDBD1A" className="w-7" />
                {' '}
                Balance Progress
              </p>
              <Button className="tracking-wider" variant="ghost" isDisabled>
                <MailPlusIcon color="#FDBD1A" />
                {' '}
                Manage Ballance History
              </Button>
            </CardHeader>
            <CardBody className="p-0 flex flex-row justify-between">
              <div style={{
                width: '100%',
                height: 300,
              }}
              >
                {computedAccountBalances && <BalanceChangeChart data={computedAccountBalances} />}
              </div>
            </CardBody>
          </Card>

        </div>
        <div
          style={{
            minWidth: 320,
          }}
          className="pl-4 pr-4 md:pl-0 md:pr-0"
        >
          <Card className="p-6 mb-8">
            <div className="text-lg tracking-wide">
              <p className="tracking-wide font-bold text-lg">
                Hi,
                {' '}
                <span className="uppercase">
                  {username || 'stranger'}
                </span>
              </p>
              <p className="text-sm tracking-wider text-content4 font-light mb-6">
                <Trans>What’s spend today?</Trans>
              </p>

              <div className="flex flex-col gap-2">
                <Button
                  className="mb-2 tracking-wider font-light uppercase"
                  size="md"
                  isDisabled={!hasAccountAndCategories}
                  color="default"
                  variant="ghost"
                  onPress={() => {
                    modalContext.addTransactionDisclosure.onOpen();
                  }}
                >
                  Add transaction
                </Button>
              </div>
            </div>
          </Card>
          <HeroWidget />
        </div>
      </div>
    </MainLayout>
  );
}

export default Index;
