import React, { useState } from 'react';
import {
  Tab, Tabs, useDisclosure,
  Modal,
  ModalContent,
} from '@nextui-org/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import AccountButton from './AccountButton';
import CreateAccountForm from './CreateAccountForm';
import EditAccountForm from './EditAccountForm';
import api, { CreateAccountParams } from '../api';
import { useHttp } from '../contexts/http';
import getCurrency from '../helpers/getCurrency';
import UITitle from './UITitle';

interface AccountsListProps {
  isDisplayBalanceInAccountCurrency: boolean;
}

const AccountsList: React.FC<AccountsListProps> = ({
  isDisplayBalanceInAccountCurrency,
}) => {
  const http = useHttp();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { isOpen: isUpdateModalOpen, onOpen: onUpdateModalOpen, onOpenChange: onUpdateModalOpenChange } = useDisclosure();
  const [selectedAccountId, setSelectedAccountId] = React.useState<string | null>(null);

  // Access the client
  const queryClient = useQueryClient();

  // Queries
  const query = useQuery({ queryKey: ['account.list'], queryFn: () => api.account.list(http) });
  // const lastAccountBalanceQuery = useQuery({ queryKey: ['account-balance.getLastAccountBalance'], queryFn: () => api.accountBalance.getLast(http) })
  const totalAccountTotalBalanceQuery = useQuery({ queryKey: ['account-balance.getTotalAccountBalance'], queryFn: () => api.accountBalance.getTotal(http) });

  // Mutations
  const createMutation = useMutation({
    mutationFn: (values: CreateAccountParams) => api.account.create(values, http),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['account.list'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.stats'] });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id: string) => api.account.delete(id, http),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['account.list'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.stats'] });
    },
  });

  const updateMutation = useMutation({
    mutationFn: (values: any) => api.account.update(values, http),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['account.list'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.stats'] });
    },
  });

  const accounts = query.data;

  const selectedAccount = accounts?.find((_account) => _account.id === selectedAccountId);

  const totalBalance = totalAccountTotalBalanceQuery.data;

  // const MyHorizontalScrollComponent = () => {
  //   return (
  //     <div className="container mx-auto overflow-x-scroll whitespace-nowrap">
  //       <div className="scrollable-content">
  //         {/* Replace these divs with your actual content */}
  //         <div className="inline-block w-48 h-48 bg-blue-500 mr-4">Item 1</div>
  //         <div className="inline-block w-48 h-48 bg-green-500 mr-4">Item 2</div>
  //         <div className="inline-block w-48 h-48 bg-red-500 mr-4">Item 3</div>
  //         {/* Add more items as needed */}
  //       </div>
  //     </div>
  //   );
  // };

  const externalAccounts = accounts?.filter((i) => i.type === 'external');

  const [accType, setAccType] = useState('internal');

  //   <div className='flex justify-end items-center w-full gap-2'>
  //   {
  //     isDisplayBalanceInAccountCurrency ? (
  //       <Button className='mr-2' size='sm' color="default" variant="ghost" onClick={() => setIsDisplayBalanceInAccountCurrency(false)}>Price in $</Button>
  //     ) : (
  //       <Button className='mr-2' size='sm' color="default" variant="ghost" onClick={() => setIsDisplayBalanceInAccountCurrency(true)}>Price in Currencies</Button>
  //     )
  //   }
  //   <Button className='mr-2' size='sm' color="default" variant="ghost" onPress={syncAccountBalancesDisclosure.onOpen}><ArrowPathIcon className="h-5 w-5 text-black dark:text-white" /> Sync balance</Button>
  //   {/* <Button className='mr-2' size='sm' color="default" variant="ghost" onClick={onOpen}>Add account</Button> */}
  // </div>

  return (
    <div style={{
    }}
    >
      <div className="flex gap-4 flex-col">
        <div className="xl:flex items-center flex justify-center">
          {/* <RadioGroup
            value='all'
            onValueChange={() => console.log('changed')}
            orientation='horizontal'
            className='w-full ml-10'
            size='sm'
            style={{
              marginTop: 2
            }}
          >
            <Radio value="all">All acounts</Radio>
            <Radio value="internal">My accounts</Radio>
          </RadioGroup> */}
          <Tabs
            size="sm"
            selectedKey={accType}
            onSelectionChange={(key: any) => {
              setAccType(key);
            }}
            className="mb-2 flex justify-center"
            variant="bordered"
            aria-label="Tabs variants"
          >
            <Tab className="tracking-wider" key="all" title="All accounts" />
            <Tab className="tracking-wider" key="internal" title="Internal" />
            <Tab className="tracking-wider" key="external" title="External" />
          </Tabs>
        </div>
        <div className="flex gap-4 flex-col md:flex-wrap md:flex-row">
          <AccountButton
            variant="ghost"
            onClick={() => {
              onOpen();
            }}
          >
            <div
              className="absolute"
              style={{
                left: '35px',
                top: 0,
                height: '100%',
                width: '13px',
                background: 'white',
              }}
            />
            <div>
              {/* <span style={{
                    width: 50,
                    height: 50,
                    backgroundColor: 'red',
                  }}></span> */}
              <span className="text-sm tracking-wider font-light">
                Add account
              </span>
            </div>
            <p className="font-bold text-xl tracking-wider">
              CREATE
            </p>

          </AccountButton>
          {(accType === 'all' || accType === 'internal') && accounts && totalBalance && accounts.filter((i) => i.type === 'internal').map((account) => {
            const totalAccountAmount = isDisplayBalanceInAccountCurrency
              ? totalBalance.find((_account: any) => _account.id === account.id)?.usdBalance || '0'
              : totalBalance.find((_account: any) => _account.id === account.id)?.balance || '0';

            return (
              <AccountButton
                key={account.id}
                variant="ghost"
                onClick={() => {
                  setSelectedAccountId(account.id);
                  onUpdateModalOpen();
                }}
              >
                <div
                  className="absolute"
                  style={{
                    left: '35px',
                    top: 0,
                    height: '100%',
                    width: '13px',
                    background: `#${account.color}`,
                  }}
                />
                <div>
                  {/* <span style={{
                    width: 50,
                    height: 50,
                    backgroundColor: 'red',
                  }}></span> */}
                  <span className="text-sm tracking-wider font-light">
                    {account.title}
                  </span>
                </div>
                <p className="font-bold text-xl tracking-wider ">
                  {isDisplayBalanceInAccountCurrency ? (
                    <span>
                      {getCurrency(totalAccountAmount, 'USD')}
                    </span>
                  ) : (
                    <span>
                      {getCurrency(totalAccountAmount, account.currencyCode)}
                    </span>
                  )}
                </p>

              </AccountButton>
            );
          })}
        </div>
        {(accType === 'all' || accType === 'external') && (externalAccounts?.length && totalBalance) ? (
          <>
            <UITitle>
              External accounts
            </UITitle>
            <div className="flex gap-4 flex-col flex-col md:flex-wrap md:flex-row">
              {externalAccounts.map((account) => {
                const totalAccountAmount = isDisplayBalanceInAccountCurrency
                  ? totalBalance.find((_account: any) => _account.id === account.id)?.usdBalance || '0'
                  : totalBalance.find((_account: any) => _account.id === account.id)?.balance || '0';

                return (
                  <AccountButton
                    key={account.id}
                    variant="ghost"
                    onClick={() => {
                      setSelectedAccountId(account.id);
                      onUpdateModalOpen();
                    }}
                  >
                    <div
                      className="absolute"
                      style={{
                        left: '35px',
                        top: 0,
                        height: '100%',
                        width: '13px',
                        background: `#${account.color}`,
                      }}
                    />
                    <div>
                      {/* <span style={{
                    width: 50,
                    height: 50,
                    backgroundColor: 'red',
                  }}></span> */}
                      <span className="text-sm tracking-wider font-light">
                        {account.title}
                      </span>
                    </div>
                    <p className="font-bold text-xl tracking-wider ">
                      {isDisplayBalanceInAccountCurrency ? (
                        <span>
                          {getCurrency(totalAccountAmount, 'USD')}
                        </span>
                      ) : (
                        <span>
                          {getCurrency(totalAccountAmount, account.currencyCode)}
                        </span>
                      )}
                    </p>

                  </AccountButton>
                );
              })}
            </div>
          </>
        ) : null}

      </div>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <CreateAccountForm onClose={onClose} onCreate={createMutation.mutate} />
          )}
        </ModalContent>
      </Modal>
      {
        selectedAccount && (
          <Modal isOpen={isUpdateModalOpen} onOpenChange={onUpdateModalOpenChange}>
            <ModalContent>
              {(onClose) => (
                <EditAccountForm
                  account={selectedAccount}
                  id={selectedAccount.id}
                  onClose={onClose}
                  onUpdate={updateMutation.mutate}
                  onDelete={deleteMutation.mutate}
                />
              )}
            </ModalContent>
          </Modal>
        )
      }

    </div>
  );
};

export default AccountsList;
