import { useState } from 'react';
import { Button } from '@nextui-org/react';
import { Trans, t } from '@lingui/macro';
import LoginForm from './LoginForm';
// import WelcomeSection from "./WelcomeSection";
import SignUpForm from './SignUpForm';

function GuestScreen() {
  const [screen, setScreen] = useState('signup');

  return (
    <main className="bg-[#26313c] h-screen flex items-center justify-center p-2">
      <div className="grid w-full h-full grid-cols-1 bg-white box-anim md:grid-cols-2 rounded-md">
        <div
          className="relative hidden md:block"
          style={{
            overflow: 'hidden',
          }}
        >
          {/* <Image
            className="object-cover "
            // fill={true}
            src="/bg.jpg"
            alt="bg-image"
          /> */}
          {/* <WelcomeSection /> */}
          {/* Insert image pig.jpeg from public folder */}
          <img
            src="/pig.jpeg"
            alt="pig"
            className="object-cover w-full h-full"
            style={{
              objectPosition: 'bottom',
              objectFit: 'contain',
            }}
          />
        </div>
        <div className="bg-default-50 text-default-900 flex items-center justify-center flex-col px-10 md:px-20 rounded-md">
          <div className="w-full">
            <div className="my-4">
              <h1 className="text-4xl font-semibold tracking-wide uppercase">
                {screen === 'login' ? 'Login' : 'Sign Up'}
              </h1>
              <p className="mt-2 text-sm text-slate-400 text-bold font-medium tracking-wide">
                <Trans>Sign Up Title</Trans>
              </p>
            </div>
            {screen === 'login' && <LoginForm />}
            {screen === 'signup' && <SignUpForm />}
            <Button
              size="sm"
              className="mt-10"
              onPress={() => {
                if (screen === 'login') {
                  setScreen('signup');
                } else {
                  setScreen('login');
                }
              }}
            >
              {screen === 'login' ? t`I don't have account` : t`I have account, login now`}
            </Button>
            <p className="mt-4 text-xs text-default-300 text-slate-400">
              @2024 All rights reserved
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}

// text-default-900 dark:text-default-900

export default GuestScreen;
