import {
  Button, Card, CardBody, CardFooter, CardHeader,
} from '@nextui-org/react';
import { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import getPreviousMonthAndYear from '../helpers/getPreviousMonthAndYear';
import getMonthNameByNumber from '../helpers/getMonthName';
import api from '../api';
import { useHttp } from '../contexts/http';

const BudgetWidget: FC<{
  confirmBudgetDisclosure: any
}> = ({
  confirmBudgetDisclosure,
}) => {
  const { month, year } = getPreviousMonthAndYear();

  const http = useHttp();

  // get reccurent budget for previous month
  const recentBudgetQuery = useQuery({ queryKey: ['recurrent-budget.get.previous-month'], queryFn: () => api.recurrentBudgetConfirm.get(year, month, http) });

  if (recentBudgetQuery.data) {
    return null;
  }

  return (
    <Card className="mb-4">
      <CardHeader className="justify-between">
        <div className="flex gap-5">
          {/* <Avatar isBordered radius="full" size="md" src="/avatars/avatar-1.png" /> */}
          <div className="flex flex-col gap-1 items-start justify-center">
            <h4 className="text-small font-semibold leading-none text-default-600">
              {getMonthNameByNumber(month)}
              {' '}
              Monthly Budget
            </h4>
          </div>
        </div>
        <Button
          className="bg-transparent text-foreground border-default-200"
          color="primary"
          radius="full"
          size="sm"
          variant="bordered"
          onPress={confirmBudgetDisclosure.onOpen}
        >
          Confirm budget
        </Button>
      </CardHeader>
      <CardBody className="px-3 py-0 text-small text-default-400">
        <p>
          You need to confirm your budget for
          {' '}
          {getMonthNameByNumber(month)}
          . Please confirm your budget by clicking the button above.
        </p>
        {/* <span className="pt-2">
          #FrontendWithZoey
          <span className="py-2" aria-label="computer" role="img">
            💻
          </span>
        </span> */}
      </CardBody>
      <CardFooter className="gap-3">
        {/* <div className="flex gap-1">
          <p className="font-semibold text-default-400 text-small">1</p>
          <p className=" text-default-400 text-small">Contributors</p>
        </div>
        <div className="flex gap-1">
          <p className="font-semibold text-default-400 text-small">{daysSinceJanFirst2024()}</p>
          <p className="text-default-400 text-small">Days of development</p>
        </div> */}
      </CardFooter>
    </Card>
  );
};

export default BudgetWidget;
