import { Modal, ModalContent } from '@nextui-org/react';
import { FC } from 'react';
// import api from "../api"
// import { useHttp } from "../contexts/http"
import SyncAccountBalancesForm from './SyncAccountBalancesForm';

const SyncAccountBalancesModal: FC<{
  syncAccountBalancesDisclosure: any
}> = ({ syncAccountBalancesDisclosure }) => (
  <Modal isOpen={syncAccountBalancesDisclosure.isOpen} onOpenChange={syncAccountBalancesDisclosure.onOpenChange}>
    <ModalContent>
      {(onClose) => (
        <SyncAccountBalancesForm onClose={onClose} />
      )}
    </ModalContent>
  </Modal>
);

export default SyncAccountBalancesModal;
