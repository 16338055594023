import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { MoveRightIcon } from 'lucide-react';
import api from '../api';
import getDate from '../helpers/getDate';
import priceWithCurrency from '../helpers/priceWithCurrency';
import { useHttp } from '../contexts/http';
import { Transfer } from '../types';

function TransfersTable({ onOpenEditTransferModal, currentMonth, currentYear }: {
  onOpenEditTransferModal: (id: string) => void,
  currentMonth: number,
  currentYear: number
}) {
  const http = useHttp();

  const accountsQuery = useQuery({ queryKey: ['account.list'], queryFn: () => api.account.list(http) });
  const transfersQuery = useQuery({
    queryKey: ['transfer.list'],
    queryFn: () => api.transfer.list({
      year: currentYear,
      month: currentMonth,
    }, http),
  });

  useEffect(() => {
    transfersQuery.refetch();
  }, [currentMonth, currentYear, transfersQuery]);

  const accounts = accountsQuery.data;
  const transfers = transfersQuery.data?.transfers;

  if (!accounts || !transfers) {
    return null;
  }

  return (
    <div>
      {transfers.length ? (
        <div style={{
          margin: '30px 0 0',
          fontWeight: 'bold',
        }}
        >
          Transfers
        </div>
      ) : null}

      <div className="pt-4 flex gap-2 flex-col">
        {transfers.map((transfer: Transfer) => (
          <div
            key={transfer.id}
            className="flex flex-row gap-6"
            onClick={() => {
              onOpenEditTransferModal(transfer.id);
            }}
          >
            <div className="bg-content1 p-4 rounded-xl w-full flex flex-row hidden lg:flex items-center">
              <MoveRightIcon width={50} color="#555555" />
              <div className="flex justify-between w-full ml-4">
                <p className="text-lg font-bold tracking-wider pr-2">
                  {priceWithCurrency(Number(transfer.usdAmount))}
                  {' '}
                  +
                  {' '}
                  {priceWithCurrency(Number(transfer.usdTax))}
                  {' '}
                  (Tax)
                </p>
              </div>
              <div className="flex gap-4">
                <div className="flex gap-4">
                  <p className="tracking-wider whitespace-nowrap">
                    <span className="text-default-200">from</span>
                    {' '}
                    <span className="text-lg">
                      {accounts.find((account) => account.id === transfer.fromAccountId)?.title}
                    </span>
                  </p>
                  <p className="tracking-wider flex flex-row gap-2 items-center whitespace-nowrap">
                    <span className="text-default-200">to</span>
                    {' '}
                    <span className="text-lg flex flex-row gap-2 items-center">
                      {accounts.find((account) => account.id === transfer.toAccountId)?.title}
                    </span>
                  </p>
                  <p className="tracking-wider flex flex-row gap-2 items-center whitespace-nowrap">
                    <span className="text-default-200">at</span>
                    {' '}
                    <span className="text-lg flex flex-row gap-2 items-center">
                      {getDate(transfer.transactionAt)}
                    </span>
                  </p>
                </div>
              </div>
              <div className="flex lg:hidden w-full flex-row gap-4 bg-background p-4 rounded-xl w-full bg-content1 flex-row justify-between" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TransfersTable;
