import {
  Button, Input, ModalBody, ModalFooter, ModalHeader, Select, SelectItem,
} from '@nextui-org/react';
import { FC } from 'react';
import { useFormik } from 'formik';
import { useQuery } from '@tanstack/react-query';
import api from '../api';
import { useHttp } from '../contexts/http';
import { currencies } from '../constants';

interface IFormInput {
  tags: any[]
}

const CreateBudgetTagForm: FC<{
  onClose: any,
  onCreate: (values: IFormInput) => void,
  tags: any[]
}> = ({ onClose, onCreate, tags }) => {
  const http = useHttp();

  const recurrentBudgetTagQuery = useQuery({ queryKey: ['recurrent-budget-tag.list'], queryFn: () => api.recurrentBudgetTag.list(http) });

  // const monthlyBudget = String(query?.data?.find(budget => budget.period === 'monthly')?.usdBudget || '0')
  // const yearlyBudget = String(query?.data?.find(budget => budget.period === 'yearly')?.usdBudget || '0')

  const formik = useFormik({
    initialValues: {
      tags: tags.map((tag) => ({
        tagId: tag.id,
        limit: '0',
        currency: 'USD',
      })),
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      // remove tag if limit is 0
      const valuesToUpdate = {
        ...values,
        tags: values.tags.filter((tag) => tag.limit !== '0'),
      };

      return onCreate(valuesToUpdate);
    },
  });

  if (!tags || !recurrentBudgetTagQuery) {
    return null;
  }

  return (
    <div>
      <ModalHeader className="flex flex-col gap-1">Add Tags Budget</ModalHeader>
      <ModalBody>
        <div style={{
          fontWeight: 'bold',
        }}
        >
          Monthly Tag Budget
        </div>
        <div style={{
          display: 'flex',
          gap: 10,
          flexDirection: 'column',
        }}
        >
          {tags.map(({ title }, idx) => (
            <div
              key={idx}
              style={{
                display: 'flex',
                gap: 10,
                marginTop: 10,
              }}
            >
              <div style={{
                width: 400,
                alignItems: 'center',
                justifyContent: 'flex-end',
                display: 'flex',
              }}
              >
                {title}
              </div>
              <Input
                label="Budget"
                name={`tags[${idx}].limit`}
                onChange={formik.handleChange}
                value={formik.values.tags[idx].limit}
              />
              <Select
                disallowEmptySelection
                label="Сurrency"
                name={`tag[${idx}].currency`}
                onChange={(e) => {
                  formik.setFieldValue(`tag[${idx}].currency`, e.target.value);
                }}
                selectedKeys={[formik.values.tags[idx].currency]}
              >
                {currencies.map((currency) => (
                  <SelectItem key={currency.id} value={currency.id}>
                    {currency.title}
                  </SelectItem>
                ))}
              </Select>
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" variant="light" onPress={onClose}>
          Close
        </Button>
        <Button
          color="primary"
          type="submit"
          onPress={() => {
            formik.submitForm();
            onClose();
          }}
        >
          Add
        </Button>
      </ModalFooter>
    </div>
  );
};

export default CreateBudgetTagForm;
