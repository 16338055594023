import {
  Button, Checkbox, Input, ModalBody, ModalFooter, ModalHeader, Radio, RadioGroup, Textarea,
} from '@nextui-org/react';
import { FC } from 'react';
import { useFormik } from 'formik';
import { Category } from '../types';
import Icon from './Icon';

interface IFormInput {
  title: string,
  description: string;
  type: string;
  icon: string;
  disabled: boolean;
}

const icons = [
  'default',
  'home',
  'clothes', 'alcohol', 'medicine', 'entertainment', 'learning', 'taxes', 'restaurants', 'shop', 'family', 'rent', 'transport', 'paycheck', 'psycho'];

const EditCategoryForm: FC<{
  onClose: any,
  id: string,
  onDelete: (id: string) => void
  onUpdate: (values: IFormInput & { id: string }) => void
  category: Category
}> = ({
  onClose, id, onDelete, onUpdate, category,
}) => {
  const initialValues: IFormInput = {
    title: category.title,
    description: category.description || '',
    type: category.type || 'all',
    icon: 'default',
    disabled: category.disabled || false,
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      onUpdate({
        ...values,
        id,
      });
    },
  });

  return (
    <div>
      <ModalHeader className="flex flex-col gap-1">Edit category</ModalHeader>
      <ModalBody>
        <RadioGroup
          label="Icon"
          color="secondary"
          name="icon"
          onChange={formik.handleChange}
          value={formik.values.icon}
          className="mb-4"
          orientation="horizontal"
        >
          {icons ? icons.map((icon) => {
            const iconKey = icon.charAt(0).toUpperCase() + icon.slice(1);
            const DynamicIcon = Icon[iconKey as keyof typeof Icon];

            return (
              <Radio key={icon} value={icon}><DynamicIcon /></Radio>
            );
          }) : []}
        </RadioGroup>
        <Input
          label="Title"
          name="title"
          onChange={formik.handleChange}
          value={formik.values.title}
        />
        <RadioGroup
          label="Type"
          color="secondary"
          name="type"
          onChange={formik.handleChange}
          value={formik.values.type}
        >
          <Radio value="all">All</Radio>
          <Radio value="income">Income</Radio>
          <Radio value="expense">Expense</Radio>
        </RadioGroup>
        <Textarea
          label="Description"
          name="description"
          onChange={formik.handleChange}
          value={formik.values.description}
        />
        <Checkbox
          name="disabled"
          color="danger"
          onChange={(e) => {
            formik.handleChange(e);
          }}
          isSelected={formik.values.disabled}
        >
          Disabled!
        </Checkbox>
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          variant="light"
          onPress={() => {
            onDelete(id);
            onClose();
          }}
        >
          Delete
        </Button>
        <Button
          variant="light"
          onPress={() => {
            onClose();
          }}
        >
          Close
        </Button>
        <Button
          color="primary"
          type="submit"
          onPress={() => {
            formik.submitForm();
            onClose();
          }}
        >
          Update
        </Button>
      </ModalFooter>
    </div>
  );
};

export default EditCategoryForm;
