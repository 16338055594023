import {
  Button,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Radio,
  RadioGroup,
  Select,
  SelectItem,
  Textarea,
} from '@nextui-org/react';
import { FC } from 'react';
import { useFormik } from 'formik';
import { CirclePicker } from 'react-color';
import { Account } from '../types';
import { currencies } from '../constants';

interface IFormInput {
  title: string,
  type: string;
  description: string;
  initialBalance: string;
  currencyCode: string;
  color: string;
}

const EditAccountForm: FC<{
  onClose: any,
  id: string,
  onDelete: (id: string) => void
  onUpdate: (values: IFormInput & { id: string }) => void,
  account: Account
}> = ({
  onClose, id, onDelete, onUpdate, account,
}) => {
  const initialValues = {
    title: account.title,
    type: account.type || 'internal',
    description: account.description || '',
    initialBalance: account.initialBalance || '0',
    currencyCode: account.currencyCode || 'USD',
    color: `${account.color}`,
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      onUpdate({
        ...values,
        id,
      });
    },
  });

  return (
    <div>
      <ModalHeader className="flex flex-col gap-1">Edit account</ModalHeader>
      <ModalBody>
        <Input
          label="Title"
          name="title"
          onChange={formik.handleChange}
          value={formik.values.title}
        />
        <RadioGroup
          label="Type"
          color="secondary"
          name="type"
          onChange={formik.handleChange}
          value={formik.values.type}
        >
          <Radio value="internal">Internal</Radio>
          <Radio value="external">External</Radio>
        </RadioGroup>
        <div style={{
          margin: '5px auto 10px',
        }}
        >
          <CirclePicker
            color={formik.values.color}
            onChangeComplete={(color) => formik.setFieldValue('color', color.hex.slice(1))}
          />
        </div>
        {/* <Input
          label="Initial balance"
          name="initialBalance"
          onChange={formik.handleChange}
          value={formik.values.initialBalance}
        /> */}
        <Select
          disallowEmptySelection
          isDisabled
          label="Select currency"
          onChange={(e) => {
            formik.setFieldValue('currencyCode', e.target.value);
          }}
          selectedKeys={[formik.values.currencyCode]}
        >
          {currencies.map((currency) => (
            <SelectItem key={currency.id} value={currency.id}>
              {currency.title}
            </SelectItem>
          ))}
        </Select>
        <Textarea
          label="Description"
          name="description"
          onChange={formik.handleChange}
          value={formik.values.description}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          variant="light"
          onPress={() => {
            onDelete(id);
            onClose();
          }}
        >
          Delete
        </Button>
        <Button
          variant="light"
          onPress={() => {
            onClose();
          }}
        >
          Close
        </Button>
        <Button
          color="primary"
          type="submit"
          onPress={() => {
            formik.submitForm();
            onClose();
          }}
        >
          Update
        </Button>
      </ModalFooter>
    </div>
  );
};

export default EditAccountForm;
