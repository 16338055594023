import { useDisclosure } from '@nextui-org/react';
import { useState } from 'react';
import AccountsList from '../components/AccountList';
import MainLayout from '../layouts/MainLayout';
import TotalBalance from '../components/TotalBalance';

function AccountsPage() {
  const syncAccountBalancesDisclosure = useDisclosure();

  const [
    isDisplayBalanceInAccountCurrency, setIsDisplayBalanceInAccountCurrency,
  ] = useState<boolean>(true);

  return (
    <MainLayout>
      <TotalBalance
        syncAccountBalancesDisclosure={syncAccountBalancesDisclosure}
        setIsDisplayBalanceInAccountCurrency={setIsDisplayBalanceInAccountCurrency}
        isDisplayBalanceInAccountCurrency={isDisplayBalanceInAccountCurrency}
      />
      <div className="p-4 md:p-0 md:pt-4">
        <AccountsList isDisplayBalanceInAccountCurrency={isDisplayBalanceInAccountCurrency} />
      </div>
    </MainLayout>
  );
}

export default AccountsPage;
