import { useMatch, useNavigate } from 'react-router-dom';
import { Link } from '@nextui-org/react';
import useModals from '../hooks/useModals';

function HeaderMobile() {
  const navigate = useNavigate();

  const navigateToDashboard = () => {
    navigate('/');
  };

  const navigateToMonthlyBudget = () => {
    navigate('/monthly-budget');
  };

  // const navigateToRoadmap = () => {
  //   navigate('/roadmap');
  // };

  // const navigateToCategoriesTags = () => {
  //   navigate('/categories-tags');
  // };

  const navigateToAccountsBallance = () => {
    navigate('/accounts-ballance');
  };

  // const navigateToReports = () => {
  //   navigate('/reports');
  // };

  const navigateToProfile = () => {
    navigate('/profile');
  };

  const isDashboardActive = useMatch('/');
  const isMonthlyBudgetActive = useMatch('/monthly-budget');
  const isProfileActive = useMatch('/profile');
  // const isRoadmapActive = useMatch('/roadmap');
  // const isCategoriesActive = useMatch('/categories-tags');
  const isAccountsBallanceActive = useMatch('/accounts-ballance');
  // const isReportsActive = useMatch('/reports');

  const { modalContext } = useModals();

  return (
    <div>
      <div className="z-50 fixed bottom-0 left-0 flex justify-between w-full bg-content2 md:hidden">
        <Link
          onClick={() => {
            navigateToDashboard();
          }}
          className="h-16 w-1/5 flex justify-center items-center"
        >
          {!isDashboardActive ? (
            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M36.4525 14.0175L24.99 4.84753C22.75 3.06253 19.25 3.04503 17.0275 4.83003L5.565 14.0175C3.92 15.33 2.9225 17.955 3.2725 20.02L5.4775 33.215C5.985 36.1725 8.7325 38.5 11.725 38.5H30.275C33.2325 38.5 36.0325 36.12 36.54 33.1975L38.745 20.0025C39.06 17.955 38.0625 15.33 36.4525 14.0175ZM22.3125 31.5C22.3125 32.2175 21.7175 32.8125 21 32.8125C20.2825 32.8125 19.6875 32.2175 19.6875 31.5V26.25C19.6875 25.5325 20.2825 24.9375 21 24.9375C21.7175 24.9375 22.3125 25.5325 22.3125 26.25V31.5Z" fill="white" />
            </svg>
          )
            : (
              <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M36.4525 14.0175L24.99 4.8475C22.75 3.0625 19.25 3.045 17.0275 4.83L5.565 14.0175C3.92 15.33 2.9225 17.955 3.2725 20.02L5.4775 33.215C5.985 36.1725 8.7325 38.5 11.725 38.5H30.275C33.2325 38.5 36.0325 36.12 36.54 33.1975L38.745 20.0025C39.06 17.955 38.0625 15.33 36.4525 14.0175ZM22.3125 31.5C22.3125 32.2175 21.7175 32.8125 21 32.8125C20.2825 32.8125 19.6875 32.2175 19.6875 31.5V26.25C19.6875 25.5325 20.2825 24.9375 21 24.9375C21.7175 24.9375 22.3125 25.5325 22.3125 26.25V31.5Z" fill="#FDBD1A" />
              </svg>
            )}
        </Link>
        <Link
          onClick={() => {
            navigateToMonthlyBudget();
          }}
          className="h-16 w-1/5 flex justify-center items-center"
        >
          {
            !isMonthlyBudgetActive ? (
              <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M31.535 21.5775L29.4 19.4425C28.8925 19.005 28.595 18.3575 28.5775 17.64C28.5425 16.8525 28.8575 16.065 29.435 15.4875L31.535 13.3875C33.355 11.5675 34.0375 9.8175 33.46 8.435C32.9 7.07 31.1675 6.3175 28.6125 6.3175H10.325V4.8125C10.325 4.095 9.73001 3.5 9.01251 3.5C8.29501 3.5 7.70001 4.095 7.70001 4.8125V37.1875C7.70001 37.905 8.29501 38.5 9.01251 38.5C9.73001 38.5 10.325 37.905 10.325 37.1875V28.6475H28.6125C31.1325 28.6475 32.83 27.8775 33.4075 26.495C33.985 25.1125 33.32 23.38 31.535 21.5775Z" fill="white" />
              </svg>
            )
              : (
                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M31.535 21.5775L29.4 19.4425C28.8925 19.005 28.595 18.3575 28.5775 17.64C28.5425 16.8525 28.8575 16.065 29.435 15.4875L31.535 13.3875C33.355 11.5675 34.0375 9.8175 33.46 8.435C32.9 7.07 31.1675 6.3175 28.6125 6.3175H10.325V4.8125C10.325 4.095 9.73 3.5 9.0125 3.5C8.295 3.5 7.7 4.095 7.7 4.8125V37.1875C7.7 37.905 8.295 38.5 9.0125 38.5C9.73 38.5 10.325 37.905 10.325 37.1875V28.6475H28.6125C31.1325 28.6475 32.83 27.8775 33.4075 26.495C33.985 25.1125 33.32 23.38 31.535 21.5775Z" fill="#FDBD1A" />
                </svg>
              )

          }
        </Link>
        <Link
          onPress={() => modalContext.addTransactionDisclosure.onOpen()}
          className="h-16 w-1/5 flex justify-center items-center"
        >
          <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_3_11935)">
              <path d="M31 10.1666C19.5208 10.1666 10.1667 19.5208 10.1667 31C10.1667 42.4791 19.5208 51.8333 31 51.8333C42.4792 51.8333 51.8333 42.4791 51.8333 31C51.8333 19.5208 42.4792 10.1666 31 10.1666ZM39.3333 32.5625H32.5625V39.3333C32.5625 40.1875 31.8542 40.8958 31 40.8958C30.1458 40.8958 29.4375 40.1875 29.4375 39.3333V32.5625H22.6667C21.8125 32.5625 21.1042 31.8541 21.1042 31C21.1042 30.1458 21.8125 29.4375 22.6667 29.4375H29.4375V22.6666C29.4375 21.8125 30.1458 21.1041 31 21.1041C31.8542 21.1041 32.5625 21.8125 32.5625 22.6666V29.4375H39.3333C40.1875 29.4375 40.8958 30.1458 40.8958 31C40.8958 31.8541 40.1875 32.5625 39.3333 32.5625Z" fill="white" />
            </g>
            <defs>
              <filter id="filter0_d_3_11935" x="0.166666" y="0.166626" width="61.6667" height="61.6666" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_11935" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_11935" result="shape" />
              </filter>
            </defs>
          </svg>

        </Link>
        <Link
          onClick={() => {
            navigateToAccountsBallance();
          }}
          className="h-16 w-1/5 flex justify-center items-center"
        >
          {
            !isAccountsBallanceActive ? (
              <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28.3325 3.5H13.6675C7.2975 3.5 3.5 7.2975 3.5 13.6675V28.315C3.5 34.7025 7.2975 38.5 13.6675 38.5H28.315C34.685 38.5 38.4825 34.7025 38.4825 28.3325V13.6675C38.5 7.2975 34.7025 3.5 28.3325 3.5ZM29.75 30.1875H12.25C11.5325 30.1875 10.9375 29.5925 10.9375 28.875C10.9375 28.1575 11.5325 27.5625 12.25 27.5625H29.75C30.4675 27.5625 31.0625 28.1575 31.0625 28.875C31.0625 29.5925 30.4675 30.1875 29.75 30.1875ZM29.75 22.3125H12.25C11.5325 22.3125 10.9375 21.7175 10.9375 21C10.9375 20.2825 11.5325 19.6875 12.25 19.6875H29.75C30.4675 19.6875 31.0625 20.2825 31.0625 21C31.0625 21.7175 30.4675 22.3125 29.75 22.3125ZM29.75 14.4375H12.25C11.5325 14.4375 10.9375 13.8425 10.9375 13.125C10.9375 12.4075 11.5325 11.8125 12.25 11.8125H29.75C30.4675 11.8125 31.0625 12.4075 31.0625 13.125C31.0625 13.8425 30.4675 14.4375 29.75 14.4375Z" fill="#D5D5D5" />
              </svg>
            )
              : (
                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M28.3325 3.5H13.6675C7.2975 3.5 3.5 7.2975 3.5 13.6675V28.315C3.5 34.7025 7.2975 38.5 13.6675 38.5H28.315C34.685 38.5 38.4825 34.7025 38.4825 28.3325V13.6675C38.5 7.2975 34.7025 3.5 28.3325 3.5ZM29.75 30.1875H12.25C11.5325 30.1875 10.9375 29.5925 10.9375 28.875C10.9375 28.1575 11.5325 27.5625 12.25 27.5625H29.75C30.4675 27.5625 31.0625 28.1575 31.0625 28.875C31.0625 29.5925 30.4675 30.1875 29.75 30.1875ZM29.75 22.3125H12.25C11.5325 22.3125 10.9375 21.7175 10.9375 21C10.9375 20.2825 11.5325 19.6875 12.25 19.6875H29.75C30.4675 19.6875 31.0625 20.2825 31.0625 21C31.0625 21.7175 30.4675 22.3125 29.75 22.3125ZM29.75 14.4375H12.25C11.5325 14.4375 10.9375 13.8425 10.9375 13.125C10.9375 12.4075 11.5325 11.8125 12.25 11.8125H29.75C30.4675 11.8125 31.0625 12.4075 31.0625 13.125C31.0625 13.8425 30.4675 14.4375 29.75 14.4375Z" fill="#FDBD1A" />
                </svg>
              )
          }
        </Link>
        <Link
          onClick={() => {
            navigateToProfile();
          }}
          className="h-16 w-1/5 flex justify-center items-center"
        >
          {
            !isProfileActive ? (
              <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28.3325 3.5H13.6675C7.2975 3.5 3.5 7.2975 3.5 13.6675V28.315C3.5 34.7025 7.2975 38.5 13.6675 38.5H28.315C34.685 38.5 38.4825 34.7025 38.4825 28.3325V13.6675C38.5 7.2975 34.7025 3.5 28.3325 3.5ZM14.875 11.165C16.6775 11.165 18.165 12.635 18.165 14.455C18.165 16.275 16.695 17.745 14.875 17.745C13.055 17.745 11.585 16.24 11.585 14.4375C11.585 12.635 13.0725 11.165 14.875 11.165ZM21 33.39C16.2925 33.39 12.46 29.5575 12.46 24.85C12.46 23.625 13.4575 22.61 14.6825 22.61H27.2825C28.5075 22.61 29.505 23.6075 29.505 24.85C29.54 29.5575 25.7075 33.39 21 33.39ZM27.125 17.71C25.3225 17.71 23.835 16.24 23.835 14.42C23.835 12.6 25.305 11.13 27.125 11.13C28.945 11.13 30.415 12.6 30.415 14.42C30.415 16.24 28.9275 17.71 27.125 17.71Z" fill="#D5D5D5" />
              </svg>
            ) : (
              <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28.3325 3.5H13.6675C7.2975 3.5 3.5 7.2975 3.5 13.6675V28.315C3.5 34.7025 7.2975 38.5 13.6675 38.5H28.315C34.685 38.5 38.4825 34.7025 38.4825 28.3325V13.6675C38.5 7.2975 34.7025 3.5 28.3325 3.5ZM14.875 11.165C16.6775 11.165 18.165 12.635 18.165 14.455C18.165 16.275 16.695 17.745 14.875 17.745C13.055 17.745 11.585 16.24 11.585 14.4375C11.585 12.635 13.0725 11.165 14.875 11.165ZM21 33.39C16.2925 33.39 12.46 29.5575 12.46 24.85C12.46 23.625 13.4575 22.61 14.6825 22.61H27.2825C28.5075 22.61 29.505 23.6075 29.505 24.85C29.54 29.5575 25.7075 33.39 21 33.39ZM27.125 17.71C25.3225 17.71 23.835 16.24 23.835 14.42C23.835 12.6 25.305 11.13 27.125 11.13C28.945 11.13 30.415 12.6 30.415 14.42C30.415 16.24 28.9275 17.71 27.125 17.71Z" fill="#FDBD1A" />
              </svg>
            )
          }
        </Link>
      </div>
    </div>
  );
}

export default HeaderMobile;
