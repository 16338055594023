import React from 'react';
import {
  Button, Modal, ModalContent, useDisclosure,
} from '@nextui-org/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '../api';
import CreateTagForm from './CreateTagForm';
import EditTagForm from './EditTagForm';
import { useHttp } from '../contexts/http';
import UITitle from './UITitle';
import TagButton from './TagButton';

const TagList: React.FC = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { isOpen: isUpdateModalOpen, onOpen: onUpdateModalOpen, onOpenChange: onUpdateModalOpenChange } = useDisclosure();
  const [selectedTagId, setSelectedTagId] = React.useState<string | null>(null);
  const http = useHttp();

  // Access the client
  const queryClient = useQueryClient();

  // Queries
  const query = useQuery({ queryKey: ['tag.list'], queryFn: () => api.tag.list(http) });

  // Mutations
  const createMutation = useMutation({
    mutationFn: (values: any) => api.tag.create(values, http),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['tag.list'] });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id: string) => api.tag.delete(id, http),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['tag.list'] });
    },
  });

  const updateMutation = useMutation({
    mutationFn: (values: any) => api.tag.update(values, http),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tag.list'] });
    },
  });

  const tags = query.data;

  const selectedTag = tags?.find((_tag) => _tag.id === selectedTagId);

  return (
    <div>
      <div style={{
        margin: '0',
      }}
      >
        <UITitle>
          TAGS
        </UITitle>
        <div style={{
          display: 'flex',
          margin: '10px 0 20px',
          gap: 10,
          flexWrap: 'wrap',
        }}
        >
          <div style={{
            width: '100%',
            gap: 10,
            display: 'flex',
            flexDirection: 'column',
          }}
          >
            {tags && tags
              .map((tag) => (
                <TagButton
                  key={tag.id}
                  title={tag.title}
                  color={tag.color}
                  onClick={() => {
                    onUpdateModalOpen();
                    setSelectedTagId(tag.id);
                  }}
                />
              ))}
          </div>
        </div>
        <Button color="default" variant="ghost" onPress={onOpen}>Add tag</Button>
      </div>

      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <CreateTagForm
              onClose={onClose}
              onCreate={
                createMutation.mutate
              }
            />
          )}
        </ModalContent>
      </Modal>
      {selectedTag && (
        <Modal isOpen={isUpdateModalOpen} onOpenChange={onUpdateModalOpenChange}>
          <ModalContent>
            {(onClose) => (
              <EditTagForm
                tag={selectedTag}
                onClose={onClose}
                onUpdate={updateMutation.mutate}
                onDelete={deleteMutation.mutate}
              />
            )}
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};

export default TagList;
