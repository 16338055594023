import {
  Button, Input, ModalBody, ModalFooter, ModalHeader, Radio, RadioGroup, Select, SelectItem, Textarea,
} from '@nextui-org/react';
import { FC } from 'react';
import { useFormik } from 'formik';
import { CirclePicker } from 'react-color';
import { currencies } from '../constants';

interface IFormInput {
  title: string,
  type: string;
  description: string;
  currencyCode: string;
  initialBalance: string;
  color: string;
}

const CreateAccountForm: FC<{
  onClose: any,
  onCreate: (values: IFormInput) => void
}> = ({ onClose, onCreate }) => {
  const formik = useFormik({
    initialValues: {
      title: '',
      type: 'internal',
      initialBalance: '0',
      description: '',
      currencyCode: 'USD',
      color: '#607D8B',
    },
    enableReinitialize: true,
    onSubmit: (values) => onCreate(values),
  });

  return (
    <div>
      <ModalHeader className="flex flex-col gap-1">Add account</ModalHeader>
      <ModalBody>
        <Input
          label="Title"
          name="title"
          onChange={formik.handleChange}
          value={formik.values.title}
        />
        <RadioGroup
          label="Type"
          color="secondary"
          name="type"
          onChange={formik.handleChange}
          value={formik.values.type}
        >
          <Radio value="internal">Internal</Radio>
          <Radio value="external">External</Radio>
        </RadioGroup>
        <div style={{
          margin: '5px auto 10px',
        }}
        >
          <CirclePicker
            color={formik.values.color}
            onChangeComplete={(color) => formik.setFieldValue('color', color.hex.slice(1))}
          />
        </div>
        {/* <Input
          label="Initial balance"
          name="initialBalance"
          onChange={formik.handleChange}
          value={formik.values.initialBalance}
        /> */}
        <Select
          disallowEmptySelection
          label="Select currency"
          onChange={(e) => {
            formik.setFieldValue('currencyCode', e.target.value);
          }}
          selectedKeys={[formik.values.currencyCode]}
        >
          {currencies.map((currency) => (
            <SelectItem key={currency.id} value={currency.id}>
              {currency.title}
            </SelectItem>
          ))}
        </Select>
        <Textarea
          label="Description"
          name="description"
          onChange={formik.handleChange}
          value={formik.values.description}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="danger" variant="light" onPress={onClose}>
          Close
        </Button>
        <Button
          color="primary"
          type="submit"
          onPress={() => {
            formik.submitForm();
            onClose();
          }}
        >
          Add
        </Button>
      </ModalFooter>
    </div>
  );
};

export default CreateAccountForm;
