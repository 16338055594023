import { FC, useState } from 'react';
import {
  Button, Card, CardBody, CardHeader, Modal, ModalContent, Progress, useDisclosure,
} from '@nextui-org/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ArrowLeftIcon, ArrowRightIcon, CheckBadgeIcon } from '@heroicons/react/24/solid';
import {
  Edit2Icon, MailPlusIcon,
} from 'lucide-react';
import api from '../api';
import { useHttp } from '../contexts/http';
import priceWithCurrency from '../helpers/priceWithCurrency';
import getMonthNameByNumber from '../helpers/getMonthName';
import MonthlyBudgetTransactions from './MonthlyBudgetTransactions';
import EditTransferForm from './EditTransferForm';
import EditTransactionModal from './EditTransactionModal';
import useMonthAndYear from '../hooks/useMonthAndYear';
import useModals from '../hooks/useModals';
import getNowDayMonthYear from '../helpers/getNowDayMonthYear';
import BudgetWidget from './BudgetWidget';

const MonthlyBudget: FC = () => {
  const { modalContext } = useModals();
  const http = useHttp();
  const queryClient = useQueryClient();

  const recentBydgetQuery = useQuery({ queryKey: ['recurrent-budget.get'], queryFn: () => api.budget.get(http) });

  const tagsQuery = useQuery({ queryKey: ['tag.list'], queryFn: () => api.tag.list(http) });

  const tags = tagsQuery.data;

  const monthlyBudget = recentBydgetQuery?.data?.find((budget) => budget.period === 'monthly');

  const {
    currentMonth, currentYear, goToNextMonth, goToPreviousMonth,
  } = useMonthAndYear();

  const transactionsStatsQuery = useQuery({ queryKey: ['monthly-budget.spend', currentYear, currentMonth], queryFn: () => api.transaction.getExpensesByMonth(currentMonth, currentYear, http) });

  const tagsExpensesPerMonthQuery = useQuery({ queryKey: ['monthly-budget.expenses.tags', currentYear, currentMonth], queryFn: () => api.transaction.getExpensesByMonthByTag(currentMonth, currentYear, http) });

  const tagsExpensesPerMonth = tagsExpensesPerMonthQuery.data;

  const recurrentBudgetTagQuery = useQuery({ queryKey: ['recurrent-budget-tag.list'], queryFn: () => api.recurrentBudgetTag.list(http) });

  const stats = transactionsStatsQuery.data;

  const percentsLeft = monthlyBudget ? ((Number(stats?.amount) / Number(monthlyBudget.usdBudget)) * 100) : 0;

  const transfersQuery = useQuery({
    queryKey: ['transfer.list'],
    queryFn: () => api.transfer.list({
      year: currentYear,
      month: currentMonth,
    }, http),
  });

  const [selectedTransactionId, setSelectedTransactionId] = useState<string | null>(null);
  const [selectedTransferId, setSelectedTransferId] = useState<string | null>(null);

  const selectedTransfer = transfersQuery.data?.transfers?.find(
    (_transfer) => _transfer.id === selectedTransferId,
  );

  const deleteTransactionMutation = useMutation({
    mutationFn: (id: string) => api.transaction.delete(id, http),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['transaction.list'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.stats'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.grouped-by-category-list'] });
      queryClient.invalidateQueries({ queryKey: ['monthly-budget.spend', currentYear, currentMonth] });
      queryClient.invalidateQueries({ queryKey: ['transaction.month-infinite'] });
    },
  });

  const deleteTransferMutation = useMutation({
    mutationFn: (id: string) => api.transfer.delete(id, http),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['transaction.list'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.stats'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.grouped-by-category-list'] });
      queryClient.invalidateQueries({ queryKey: ['monthly-budget.spend', currentYear, currentMonth] });
      queryClient.invalidateQueries({ queryKey: ['transaction.month-infinite'] });
    },
  });

  const updateTransactionMutation = useMutation({
    mutationFn: (values: any) => api.transaction.update(values, http),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['transaction.list'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.stats'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.grouped-by-category-list'] });
      queryClient.invalidateQueries({ queryKey: ['monthly-budget.spend', currentYear, currentMonth] });
      queryClient.invalidateQueries({ queryKey: ['transaction.month-infinite'] });
    },
  });

  const updateTransferMutation = useMutation({
    mutationFn: (values: any) => api.transfer.update(values, http),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['transaction.list'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.stats'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.grouped-by-category-list'] });
      queryClient.invalidateQueries({ queryKey: ['monthly-budget.spend', currentYear, currentMonth] });
      queryClient.invalidateQueries({ queryKey: ['transaction.month-infinite'] });
    },
  });

  const {
    isOpen: isUpdateTransactionModalOpen,
    onOpen: onUpdateTransactionModalOpen,
    onOpenChange: onUpdateTransactionModalOpenChange,
  } = useDisclosure();
  const {
    isOpen: isUpdateTransferModalOpen,
    onOpen: onUpdateTransferModalOpen,
    onOpenChange: onUpdateTransferModalOpenChange,
  } = useDisclosure();

  const recurrentBudgetTag = recurrentBudgetTagQuery.data;

  if (!monthlyBudget || !stats || !tags || !tagsExpensesPerMonth || !recurrentBudgetTag) {
    return (
      <div className="flex justify-center">
        <Button color="warning" variant="ghost" onPress={modalContext?.addBudgetDisclosure?.onOpen}>Start track budget</Button>
      </div>
    );
  }

  const recurrentTagsProgress = recurrentBudgetTag.map((recurrentTag: any) => {
    const tagExpenses = tagsExpensesPerMonth.find((_tag: any) => _tag.tagId === recurrentTag.tagId);
    const tag = tags.find((_tag: any) => _tag.id === recurrentTag.tagId);

    return {
      expenses: tagExpenses,
      budget: recurrentTag,
      tag,
    };
  });

  const renderTags = () => (
    <Card className="w-full">
      <CardHeader className="flex justify-between flex-row items-center p-4 pl-6 pr-6 bg-content2 rounded-lg">
        <p className="tracking-wider font-light text-sm lg:text-md">
          Budget By Tag
        </p>
        <Button isIconOnly size="sm" color="default" variant="ghost" onPress={modalContext?.addBudgetTagDisclosure?.onOpen}><Edit2Icon size={15} /></Button>
      </CardHeader>

      <CardBody className="p-4 md:p-8 flex gap-1">
        {recurrentTagsProgress.map((agrTag: any, idx: any) => {
          // const colorBg = agrTag ? `bg-[#${agrTag.tag.color}]` : 'bg-[#50d71e]'

          const $percentsLeft = ((Number(agrTag.expenses?.totalAmount || 0) / Number(agrTag.budget.limit)) * 100);

          return (
            <div className="mb-4" key={idx}>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
              >

                <div className="text-default text-sm mb-2 tracking-wider text-default-700">{agrTag.tag.title}</div>
                <div className="text-default text-sm mb-2 tracking-wider text-default-700">
                  {`${priceWithCurrency(agrTag.expenses?.totalAmount || 0)}/${priceWithCurrency(Number(agrTag.budget.limit))}$`}
                </div>
              </div>
              <Progress
                isStriped
                aria-label="Loading..."
                size="md"
                color={$percentsLeft < 80 ? 'warning' : 'danger'}
                value={$percentsLeft}
                className="max-w-md w-full"
                radius="full"
                classNames={{
                  base: 'max-w-none',
                  // track: "h-4 bg-#000000",
                  indicator: `bg-[#${agrTag.tag.color}] `,
                }}
              />

            </div>
          );
        })}
      </CardBody>
    </Card>

  );

  return (
    <div className="">
      <div className="flex flex-col md:flex-row justify-between w-full">
        <div className="w-full">
          <Card className="w-full">
            <CardHeader className="flex justify-between bg-content2 rounded-xl p-4 pl-6 pr-6">
              <div className="flex items-center">
                <Button
                  className="mr-4"
                  isIconOnly
                  color="default"
                  variant="ghost"
                  onPress={modalContext?.addBudgetDisclosure?.onOpen}
                >
                  <Edit2Icon size={15} />
                </Button>
                <p className=" tracking-wider font-light text-sm lg:text-md">
                  {getMonthNameByNumber(currentMonth)}
                  {' '}
                  {currentYear}
                  {' '}
                  Budget
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '10px',
                }}
                className="md:ml-2"
              >
                <Button isIconOnly variant="ghost" onClick={goToPreviousMonth}>
                  <ArrowLeftIcon className="h-4 w-4 text-default-900 dark:text-default-900" />
                </Button>
                <Button isIconOnly variant="ghost" onClick={goToNextMonth}>
                  <ArrowRightIcon className="h-4 w-4 text-default-900 dark:text-default-900" />
                </Button>
              </div>
            </CardHeader>
            <CardBody className="p-6 pt-8">
              <div
                className="text-xl font-bold tracking-wider whitespace-nowrap text-center mb-6"
              >
                {priceWithCurrency(Number(stats?.amount))}
                {' '}
                SPENT
              </div>
              <Progress
                isStriped
                aria-label="Loading..."
                size="lg"
                color={percentsLeft < 80 ? 'warning' : 'danger'}
                value={percentsLeft}
                className="max-w-md w-full"
                radius="full"
                classNames={{
                  base: 'max-w-none rounded-lg h-8 bg-default1 mb-4',
                  track: 'rounded-lg h-8',
                  indicator: 'rounded-lg h-8',
                }}
              />
              <div className="flex justify-between tracking-wider">
                <div className="flex flex-col sm:flex-row md:items-end gap-1 items-start">
                  <span className="font-bold text-xl">
                    {priceWithCurrency(Number(
                      Math.round((monthlyBudget.usdBudget - Number(stats?.amount)) * 100) / 100,
                    ))}
                  </span>
                  {' '}
                  <span className="font-light text-xs">LEFT</span>
                </div>
                <div className="flex items-end gap-1 flex-col-reverse sm:flex-row">
                  <span className="font-light text-xs">OUT OF</span>
                  {' '}
                  <span className="font-bold text-xl">{priceWithCurrency(Number(monthlyBudget.usdBudget))}</span>
                </div>
              </div>
            </CardBody>
          </Card>
          <div className="p-4 md:p-0">

            <div className="md:hidden">
              {renderTags()}
            </div>
            <Card className="w-full mt-8">
              <CardHeader className="flex justify-between flex-row items-center p-4 pl-6 pr-4 bg-content2 rounded-lg">
                <p className="tracking-wider font-light text-sm lg:text-md flex flex-row gap-2 justify-between w-full">
                  <span className="flex justify-center items-center flex-row gap-2 justify-center tracking-wider">
                    <MailPlusIcon color="#FDBD1A" />
                    Filters for transactions and view settings
                  </span>
                  <Button
                    variant="bordered"
                    size="md"
                    className=""
                    onPress={() => {
                      const { day, month, year } = getNowDayMonthYear(new Date());

                      queryClient.setQueryData(['localCache.createExpenseValues'], {
                        day,
                        month: String(Number(month)),
                        year,
                      });

                      modalContext.addTransactionDisclosure.onOpen();
                    }}
                  >
                    Add expense
                  </Button>
                </p>
              </CardHeader>
            </Card>
            <MonthlyBudgetTransactions
              onOpenEditTransactionModal={(id: string) => {
                setSelectedTransactionId(id);
                onUpdateTransactionModalOpen();
              }}
              onOpenEditTransferModal={(id: string) => {
                setSelectedTransferId(id);
                onUpdateTransferModalOpen();
              }}
              currentMonth={currentMonth}
              currentYear={currentYear}
            />

            {/* Edit Transaction modal */}
            {selectedTransactionId && (
              <EditTransactionModal
                selectedTransactionId={selectedTransactionId}
                isUpdateTransactionModalOpen={isUpdateTransactionModalOpen}
                onUpdateTransactionModalOpenChange={onUpdateTransactionModalOpenChange}
                updateTransactionMutation={updateTransactionMutation}
                deleteTransactionMutation={deleteTransactionMutation}
              />
            )}
            {/* Edit Transfer modal */}
            {selectedTransfer && (
              <Modal
                isOpen={isUpdateTransferModalOpen}
                onOpenChange={onUpdateTransferModalOpenChange}
              >
                <ModalContent>
                  {(onClose) => (
                    <EditTransferForm
                      id={selectedTransfer.id}
                      transfer={selectedTransfer}
                      onClose={onClose}
                      onUpdate={(values) => updateTransferMutation.mutate(values)}
                      onDelete={deleteTransferMutation.mutate}
                    />
                  )}
                </ModalContent>
              </Modal>
            )}
          </div>
        </div>
        <div className="hidden md:block md:ml-8 md:w-2/5">
          <BudgetWidget confirmBudgetDisclosure={modalContext?.confirmBudgetDisclosure} />
          {renderTags()}
          <Card className="mb-4 mt-4">
            <CardBody className="p-4">
              <p className="flex flex-row gap-2 tracking-wider font-light text-sm">
                <CheckBadgeIcon className="w-4" />
                {' '}
                Days till end of month:
                <span className="font-bold">{new Date(currentYear, currentMonth, 0).getDate() - new Date().getDate()}</span>
              </p>
              <p className="flex flex-row gap-2 tracking-wider font-light text-sm">
                <CheckBadgeIcon className="w-4" />
                {' '}
                Daily budget till end of month:
                <span className="font-bold">{priceWithCurrency(Number(monthlyBudget.usdBudget - Number(stats?.amount)) / (new Date(currentYear, currentMonth, 0).getDate() - new Date().getDate()))}</span>
              </p>
              {/* Average Spent Per Day */}
              <p className="flex flex-row gap-2 tracking-wider font-light text-sm">
                <CheckBadgeIcon className="w-4" />
                {' '}
                Average spent per day:
                <span className="font-bold">{priceWithCurrency(Number(stats?.amount) / new Date().getDate())}</span>
              </p>
              {/* Left today to spend */}
              {/* <p className="flex flex-row gap-2 tracking-wider font-light text-sm">
                <CheckBadgeIcon className="w-4" />
                {' '}
                Left today to spend:
                <span className="font-bold">
                  {priceWithCurrency(
                    Number(monthlyBudget.usdBudget - Number(stats?.amount)) / new Date().getDate(),
                  )}
                </span>
              </p> */}
              {/* Projected Month-End Spending */}
              {/* <p className="flex flex-row gap-2 tracking-wider font-light text-sm">
                <CheckBadgeIcon className="w-4" />
                Projected month-end spending:
                {' '}
                <span className="font-bold">
                  {
                    priceWithCurrency(
                      Number(stats?.amount) / new Date().getDate() * new Date(currentYear, currentMonth, 0).getDate(),
                    )
                  }
                </span>
              </p> */}
            </CardBody>
          </Card>
          <Card className="w-full mt-4">
            <CardHeader className="flex justify-between flex-row items-center p-4 pl-6 pr-6 bg-content2 rounded-lg">
              <p className="tracking-wider font-light text-sm lg:text-md flex flex-row gap-2 justify-between w-full">
                <span className="flex justify-center items-center flex-row gap-2 justify-center tracking-wider">
                  <MailPlusIcon color="#FDBD1A" />
                  Budgets by Categories
                </span>
                <span className="flex justify-center items-center tracking-wider">
                  Coming soon
                </span>
              </p>
            </CardHeader>
          </Card>
          <Card className="w-full mt-4">
            <CardHeader className="flex justify-between flex-row items-center p-4 pl-6 pr-6 bg-content2 rounded-lg">
              <p className="tracking-wider font-light text-sm lg:text-md flex flex-row gap-2 justify-between w-full">
                <span className="flex justify-center items-center flex-row gap-2 justify-center tracking-wider">
                  <MailPlusIcon color="#FDBD1A" />
                  Recurrent transactions
                </span>
                <span className="flex justify-center items-center tracking-wider">
                  Coming soon
                </span>
              </p>
            </CardHeader>
          </Card>
          {/* <Card className="p-6 mb-8">
            <div className="text-lg tracking-wide">
              <p className="tracking-wide font-bold text-lg">
                Hi,
                {' '}
                <span className="uppercase">
                  {username || 'stranger'}
                </span>
              </p>
              <p className="text-sm tracking-wider text-content4 font-light mb-6">
                What’s spend today?
              </p>

              <div className="flex flex-col">
                <Button
                  className="mb-2"
                  size="md"
                  isDisabled={!hasAccountAndCategories}
                  color="warning"
                  variant="ghost"
                  onPress={addExpenseDisclosure.onOpen}
                >
                  Add expense
                </Button>
                <Button
                  className="mb-2"
                  size="md"
                  isDisabled={!hasAccountAndCategories}
                  color="primary"
                  variant="ghost"
                  onPress={() => {
                    addTransactionDisclosure.onOpen();
                  }}
                >
                  Add income
                </Button>
                <Button
                  size="md"
                  isDisabled={!hasAccountAndCategories}
                  color="default"
                  variant="ghost"
                  onPress={addTransferDisclosure.onOpen}
                >
                  Add transfer
                </Button>
              </div>
            </div>
          </Card> */}
        </div>
      </div>

    </div>
  );
};

export default MonthlyBudget;
