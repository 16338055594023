import {
  Autocomplete,
  AutocompleteItem,
  Button, Card, CardBody, CardHeader, Checkbox, Input, ModalBody, ModalFooter, Select, SelectItem, Switch, Textarea,
} from '@nextui-org/react';
import { FC, useState } from 'react';
import { useFormik } from 'formik';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Trash2Icon, PlusIcon, MinusIcon, LockIcon, ChevronDownIcon, ChevronUp, TagIcon,
} from 'lucide-react';
import api from '../api';
import { currencies } from '../constants';
import { useHttp } from '../contexts/http';
import getNowDayMonthYear from '../helpers/getNowDayMonthYear';
import getMonthNameByNumber from '../helpers/getMonthName';
import getPreviousDay from '../helpers/getPreviousDay';
import getNextDay from '../helpers/getNextDay';
import CategoryIcon from './CategoryIcon';

interface IFormInput {
  type: string;
  amount: string;
  description: string;
  day: string;
  month: string;
  year: string;
  categoryId: string;
  accountId: string;
  currencyCode: string;
  comment: string;
  splittedCategories: {
    categoryId: string,
    amount: string,
  }[]
  tags: string[]
}

const now = getNowDayMonthYear();

const CreateTransactionForm: FC<{
  onClose: any,
  onCreate: (values: IFormInput) => void
}> = ({ onClose, onCreate }) => {
  const http = useHttp();
  const categoriesQuery = useQuery({ queryKey: ['category.list'], queryFn: () => api.category.list(http) });
  const accountsQuery = useQuery({ queryKey: ['account.list'], queryFn: () => api.account.list(http) });
  const tagsQuery = useQuery({ queryKey: ['tag.list'], queryFn: () => api.tag.list(http) });

  const categories = categoriesQuery.data;
  const accounts = accountsQuery.data;
  const tags = tagsQuery.data;

  const queryClient = useQueryClient();

  const setCacheCreateExpensesCache = ({
    accountId,
    day,
    month,
    year,
  }: {
    accountId: string,
    day: string;
    month: string;
    year: string;
  }) => {
    queryClient.setQueryData(['localCache.createExpenseValues'], {
      day,
      month,
      year,
    });

    queryClient.setQueryData(['localCache.createExpenseValuesAccount'], {
      accountId,
    });
  };

  const cachedCreateExpenseValues = queryClient.getQueryData(['localCache.createExpenseValues']) as {
    day: string
    month: string
    year: string
  } || null;

  const cachedCreateExpenseValuesAccount = queryClient.getQueryData(['localCache.createExpenseValuesAccount']) as {
    accountId?: string
  } || null;

  const [isSplitCategory, setIsSplitCategory] = useState(false);

  const getInitialAccount = () => {
    if (cachedCreateExpenseValues && cachedCreateExpenseValuesAccount?.accountId) {
      return cachedCreateExpenseValuesAccount.accountId;
    }

    if (accounts) {
      return accounts?.filter((acc) => acc.type === 'internal')[0].id;
    }

    return '';
  };

  const getInitialCurrencyCode = () => {
    if (accounts) {
      const initialAccount = getInitialAccount();

      if (initialAccount) {
        return accounts?.filter((acc) => acc.id === initialAccount)[0].currencyCode;
      }

      return accounts.filter((acc) => acc.type === 'internal')[0].currencyCode;
    }

    return 'USD';
  };

  const getInitialDay = () => {
    if (cachedCreateExpenseValues) {
      return cachedCreateExpenseValues.day;
    }

    return now.day;
  };

  const getInitialMonth = () => {
    if (cachedCreateExpenseValues) {
      return cachedCreateExpenseValues.month;
    }

    return String(Number(now.month));
  };

  const getInitialYear = () => {
    if (cachedCreateExpenseValues) {
      return cachedCreateExpenseValues.year;
    }

    return now.year;
  };

  const formik = useFormik({
    initialValues: {
      type: 'expense',
      amount: '0',
      description: '',
      day: getInitialDay(),
      month: getInitialMonth(),
      year: getInitialYear(),
      categoryId: categories ? categories[0].id : '',
      accountId: getInitialAccount(),
      currencyCode: getInitialCurrencyCode(),
      comment: '',
      splittedCategories: [] as {
        categoryId: string,
        amount: string,
      }[],
      tags: [] as string[],
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setCacheCreateExpensesCache({
        accountId: values.accountId,
        day: values.day,
        month: values.month,
        year: values.year,
      });

      onCreate(values);
    },
  });

  const { splittedCategories } = formik.values;

  const removeSplittedCategory = (index: number) => {
    if (splittedCategories.length === 1) {
      return;
    }
    formik.setFieldValue('splittedCategories', splittedCategories.filter((_, i) => i !== index));
  };

  const addSplittedCategory = () => {
    formik.setFieldValue('splittedCategories', [...splittedCategories, {
      categoryId: '',
      amount: '0',
    }]);
  };

  const [isCommentVisible, setIsCommentVisible] = useState(false);

  if (!accounts || !categories) {
    return null;
  }

  const reduceDayInForm = () => {
    const previousDay = getPreviousDay(`${formik.values.month.padStart(2, '0')}-${formik.values.day}-${formik.values.year}`);
    const formated = getNowDayMonthYear(previousDay);
    formik.setFieldValue('day', formated.day);
    formik.setFieldValue('month', String(Number(formated.month)));
    formik.setFieldValue('year', formated.year);
  };

  const increaseDayInForm = () => {
    const previousDay = getNextDay(`${formik.values.month.padStart(2, '0')}-${formik.values.day}-${formik.values.year}`);
    const formated = getNowDayMonthYear(previousDay);
    formik.setFieldValue('day', formated.day);
    formik.setFieldValue('month', String(Number(formated.month)));
    formik.setFieldValue('year', formated.year);
  };

  const renderIndexScreen = () => (
    <>
      <ModalBody>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 10,
          justifyContent: 'center',
          marginBottom: 20,
          // width: 450,
        }}
        >
          <div style={{
            display: 'flex',
            gap: 10,
            alignItems: 'center',
          }}
          >
            <Button
              size="sm"
              isIconOnly
              variant="ghost"
              onPress={() => {
                reduceDayInForm();
              }}
            >
              <MinusIcon size={20} />
            </Button>
            <Input
              size="sm"
              name="day"
              onChange={formik.handleChange}
              value={formik.values.day}
              style={{
                width: 20,
              }}
            />
            <Select
              disallowEmptySelection
              size="sm"
              name="month"
              onChange={formik.handleChange}
              value={formik.values.month}
              selectedKeys={[formik.values.month]}
              style={{
                width: '120px',
              }}
            >
              {
                Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                  <SelectItem key={month} value={String(month).padStart(2, '0')}>
                    {getMonthNameByNumber(month)}
                  </SelectItem>
                ))
              }
            </Select>
            <Input
              size="sm"
              name="year"
              onChange={formik.handleChange}
              value={formik.values.year}
              style={{
                width: 40,
              }}
            />
            <Button
              size="sm"
              isIconOnly
              variant="ghost"
              onPress={() => {
                increaseDayInForm();
              }}
            >
              <PlusIcon size={20} />
            </Button>
          </div>
        </div>

        <div
          className="flex gap-2"
        >
          <div className="w-[842px]">
            <Autocomplete
              className="w-[100%]"
              size="sm"
              onSelectionChange={(value) => {
                formik.setFieldValue('accountId', value);
                formik.setFieldValue('currencyCode', accounts.find((acc) => acc.id === value)?.currencyCode || 'USD');
              }}
              selectedKey={formik.values.accountId}
              startContent={(
                <div className="pointer-events-none flex items-center">
                  <span className="text-default-400 text-small">Acc:</span>
                </div>
            )}
            >
              {accounts ? accounts.map((account) => (
                <AutocompleteItem key={account.id} value={account.id}>
                  {account.title}
                </AutocompleteItem>
              )) : []}
            </Autocomplete>
          </div>
          {!isSplitCategory && (
            <Input
              size="sm"
              placeholder="0.00"
              startContent={(
                <div className="pointer-events-none flex items-center">
                  <span className="text-default-400 text-small">Price:</span>
                </div>
              )}
              isDisabled={isSplitCategory}
              name="amount"
              onChange={formik.handleChange}
              value={formik.values.amount}
            />
          )}
          {
            isSplitCategory && (
              <Input
                size="sm"
                placeholder="0.00"
                startContent={(
                  <div className="pointer-events-none flex items-center">
                    <span className="text-default-400 text-small">Price:</span>
                  </div>
                )}
                isDisabled={isSplitCategory}
                value={String(Number(formik.values.amount) - splittedCategories.reduce((acc, { amount }) => acc + Number(amount), 0))}
              />
            )
          }
          <Select
            disallowEmptySelection
            style={{
              width: '140px',
            }}
            size="sm"
            onChange={(e) => {
              formik.setFieldValue('currencyCode', e.target.value);
            }}
            selectedKeys={[formik.values.currencyCode]}
          >
            {currencies.map((currency) => (
              <SelectItem key={currency.id} value={currency.id}>
                {currency.title}
              </SelectItem>
            ))}
          </Select>
        </div>

        <div className="w-[100%]">
          <Autocomplete
            size="sm"
            onSelectionChange={(value) => {
              formik.setFieldValue('categoryId', value);
            }}
            isDisabled={isSplitCategory}
            endContent={
              isSplitCategory
                ? (
                  <div className="pointer-events-none flex items-center">
                    <LockIcon size={15} />
                  </div>
                ) : null
            }
            selectedKey={formik.values.categoryId}
            disableAnimation
            startContent={(
              <div className="pointer-events-none flex items-center">
                <CategoryIcon size="5" icon={categories.find((cat) => cat.id === formik.values.categoryId)?.icon || 'default'} />
              </div>
            )}
          >
            {categories ? categories.map((category) => (
              <AutocompleteItem key={category.id} value={category.id}>
                {category.title}
              </AutocompleteItem>
            )) : []}
          </Autocomplete>
        </div>
        <div className="flex gap-3">
          <Card
            className="bg-content2 w-[100%] mb-1"
          >
            <CardHeader>
              <Switch
                size="sm"
                isSelected={isSplitCategory}
                onValueChange={(...props) => {
                  if (isSplitCategory) {
                    formik.setFieldValue('splittedCategories', []);
                  } else {
                    formik.setFieldValue('splittedCategories', [{
                      categoryId: formik.values.categoryId,
                      amount: formik.values.amount,
                    }]);
                  }
                  setIsSplitCategory(...props);
                }}
              >
                Split in Categories?
              </Switch>
            </CardHeader>
          </Card>
        </div>
        {isSplitCategory && (
          <div>
            {splittedCategories.map((category, index) => (
              <Card key={category.categoryId} className="mb-4 p-4 bg-content2">
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 10,
                  alignItems: 'center',
                }}
                >
                  <Select
                    disallowEmptySelection
                    size="sm"
                    onChange={(e) => {
                      // formik.setFieldValue('s', e.target.value)
                      formik.setFieldValue(`splittedCategories[${index}].categoryId`, e.target.value);
                    }}
                    style={{
                      width: 200,
                    }}
                    value={formik.values.splittedCategories[index].categoryId}
                    selectedKeys={[formik.values.splittedCategories[index].categoryId]}
                    startContent={(
                      <div className="pointer-events-none flex items-center">
                        <span className="text-default-400 text-small">Category:</span>
                      </div>
                    )}
                  >
                    {categories ? categories.map(({ id: categoryId, title: categoryTitle }) => (
                      <SelectItem key={categoryId} value={categoryId}>
                        {categoryTitle}
                      </SelectItem>
                    )) : []}
                  </Select>
                  <Input
                    onChange={(e) => {
                      formik.setFieldValue(`splittedCategories[${index}].amount`, e.target.value);
                    }}
                    value={
                      formik.values.splittedCategories[index].amount
                    }
                    size="sm"
                    style={{
                      width: 50,
                    }}
                  />
                  <Select
                    disallowEmptySelection
                    // startContent={
                    //   <div className="pointer-events-none flex items-center">
                    //     <span className="text-default-400 text-small">Currency:</span>
                    //   </div>
                    // }
                    isDisabled
                    size="sm"
                    onChange={() => {
                      // formik.setFieldValue('currencyCode', e.target.value)
                      // formik.setFieldValue(`splittedCategories[${index}].currencyCode`, e.target.value)
                    }}
                    selectedKeys={[formik.values.currencyCode]}
                  >
                    {currencies.map((currency) => (
                      <SelectItem key={currency.id} value={currency.id}>
                        {currency.title}
                      </SelectItem>
                    ))}
                  </Select>
                  <Button
                    isDisabled={splittedCategories.length === 1}
                    size="sm"
                    color="danger"
                    isIconOnly
                    variant="light"
                    onPress={() => {
                      removeSplittedCategory(index);
                    }}
                  >
                    <Trash2Icon size={20} />
                  </Button>
                  <Button
                    isDisabled={splittedCategories.length === 3}
                    variant="bordered"
                    size="sm"
                    isIconOnly
                    onPress={() => {
                      addSplittedCategory();
                    }}
                  >
                    <PlusIcon size={20} />
                  </Button>
                </div>
              </Card>
            ))}
          </div>
        )}
        <div style={{
          marginBottom: 10,
        }}
        >
          <Card>
            <CardBody style={{
              gap: 20,
              flexDirection: 'row',
            }}
            >
              {tags && tags.map((tag) => (
                <Checkbox
                  key={tag.id}
                  icon={<TagIcon />}
                  onChange={() => {
                    if (formik.values.tags.includes(tag.id)) {
                      formik.setFieldValue('tags', formik.values.tags.filter((_tag) => _tag !== tag.id));
                    } else {
                      formik.setFieldValue('tags', [...formik.values.tags, tag.id]);
                    }
                  }}
                  isSelected={formik.values.tags.includes(tag.id)}
                >
                  {tag.title}
                </Checkbox>
              ))}
            </CardBody>
          </Card>
        </div>
        <div>
          <Button
            size="sm"
            onPress={() => {
              setIsCommentVisible(!isCommentVisible);
            }}
          >
            Comment
            {' '}
            {!isCommentVisible ? <ChevronDownIcon /> : <ChevronUp />}
          </Button>
        </div>
        {
          isCommentVisible && (
            <Textarea
              startContent={(
                <div className="pointer-events-none flex items-center">
                  <span className="text-default-400 text-small">Comment:</span>
                </div>
              )}
              size="sm"
              name="comment"
              onChange={formik.handleChange}
              value={formik.values.comment}
            />
          )
        }
      </ModalBody>
      <ModalFooter>
        <Button color="danger" variant="light" onPress={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          type="submit"
          onPress={() => {
            formik.submitForm();
            onClose();
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </>
  );

  return renderIndexScreen();
};

export default CreateTransactionForm;
