import { useQuery } from '@tanstack/react-query';
import api from '../api';
import { useHttp } from '../contexts/http';

const useQueryAccountsAndCategories = () => {
  const http = useHttp();

  const categoriesQuery = useQuery({ queryKey: ['category.list'], queryFn: () => api.category.list(http) });
  const accountsQuery = useQuery({ queryKey: ['account.list'], queryFn: () => api.account.list(http) });

  return {
    categoriesQuery,
    accountsQuery,
  };
};

export default useQueryAccountsAndCategories;
