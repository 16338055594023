import { Modal, ModalContent } from '@nextui-org/react';
import { FC } from 'react';
import { useMutation } from '@tanstack/react-query';
import ConfirmBudgetForm from './ConfirmBudgetForm';
import api from '../api';
import { useHttp } from '../contexts/http';
import getPreviousMonthAndYear from '../helpers/getPreviousMonthAndYear';

const ConfirmBudgetModal: FC<{
  addBudgetDisclosure: any
}> = ({ addBudgetDisclosure }) => {
  const http = useHttp();
  const { month, year } = getPreviousMonthAndYear();

  // Access the client
  // const queryClient = useQueryClient()

  // Mutations
  const createMutation = useMutation({
    mutationFn: () => api.recurrentBudgetConfirm.create({
      month,
      year,
    }, http),
  });

  return (
    <Modal isOpen={addBudgetDisclosure.isOpen} onOpenChange={addBudgetDisclosure.onOpenChange}>
      <ModalContent>
        {(onClose) => (
          <ConfirmBudgetForm
            month={month}
            year={year}
            onClose={onClose}
            onCreate={(values) => {
              createMutation.mutate(values);
            }}
          />
        )}
      </ModalContent>
    </Modal>
  );
};

export default ConfirmBudgetModal;
