import {
  Button, Input,
} from '@nextui-org/react';
import { useFormik } from 'formik';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHttp } from '../contexts/http';
import api from '../api';

interface IFormInput {
  username: string,
  password: string;
}

const LoginForm = () => {
  const http = useHttp();
  const queryClient = useQueryClient();

  // TODO: auth in separate HOC
  const loginMutation = useMutation({
    mutationFn: (values: IFormInput) => api.user.login(values, http),
    onSuccess: (user) => {
      // Invalidate and refetch
      localStorage.setItem('token', user.tokens.jwtToken);
      localStorage.setItem('refreshToken', user.tokens.refreshToken);
      queryClient.invalidateQueries({ queryKey: ['auth'] });
    },
  });

  const initialValues = {
    username: '',
    password: '',
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      loginMutation.mutate(values);
    },
  });

  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        style={{
          maxWidth: 400,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
        }}
      >
        <Input
          name="username"
          onChange={formik.handleChange}
          value={formik.values.username}
          placeholder="Login"
          isDisabled={loginMutation.isPending}
        />
        <Input
          name="password"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          placeholder="Password"
          isDisabled={loginMutation.isPending}
        />
        <Button
          color="primary"
          type="submit"
          onPress={() => {
            formik.submitForm();
          }}
          isDisabled={loginMutation.isPending}
          isLoading={loginMutation.isPending}
        >
          Login
        </Button>
        <p className="text-red-500 text-sm">{loginMutation.isError ? loginMutation.error?.message : ''}</p>
      </form>
    </div>
  );
};

export default LoginForm;
