import {
  Card, CardBody, CardFooter, CardHeader,
} from '@nextui-org/react';
import MainLayout from '../layouts/MainLayout';

function daysSinceJanFirst2024() {
  const startDate: any = new Date('2024-01-01');
  const currentDate: any = new Date();

  // Calculate the difference in milliseconds
  const diffInMs = currentDate - startDate;

  // Convert milliseconds to days
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  return diffInDays + 1;
}

const updates = [
  {
    date: '18 March — 17 June',
    updates: [
      'Improve stats dashboard',
      'Fix bugs in pagination',
      'Refactor modals and forms',
      'Improve add transaction UI',
      'Lots of minor bug fixes',
      'Improve mobile UI',
      'Fix timezone bug',
    ],
  },
  {
    date: '11 March — 17 March',
    updates: [
      'Added new UI (BREAKING CHANGE)',
      'Added edit tags in transaction',
      'Added remove tags in transaction',
      'Added new icon for toggle theme',
    ],
  },
  {
    date: '4 March — 10 March',
    updates: [
      'Added tags',
      'Added transaction tags',
      'Added recurrent tags to monthly budget',
    ],
  },
  {
    date: '26 February — 3 March',
    updates: [
      'Added in add expense form the ability to cache last used account and date',
      'Added in add expense form the ability to set date via arrow keys',
      'Added better header UI',
      'Added spinner and quotes on loading',
      'Fixed categories page showing all transactions',
      'Fixed edit transaction form not showing correct amount',
    ],
  },
  {
    date: '19 — 25 February',
    updates: [
      'Added recurrent monthly budget confirmation',
      'Hid confirmation widget after confimation',
      'Added fireworks on confirmation for budget',
      'Added /category page',
      'Added incomes diagram',
      'Added categories tabs on Dashboard',
      'Added caching for last used account in “Add Expense” form',
      'Fixed tabs on Dashboard Account widget',
    ],
  },
  {
    date: '12 — 18 February Updates',
    updates: [
      'Added expenses in several categories in one expense',
      'Add Income Statistic in the Dashboard',
      'Redesigned “Add Expense form',
      'Added external accounts which is used for assets which is not in your disposal',
      'Added total balance calculation settings (internal accounts / all accounts)',
      'Added Welcome screen and SignUp page',
      'Added theme saved in user preferences',
      'Fixed UI bugs on newbie accounts',
      'Fixed total balance and expenses calculation',
      'Fixed Edit transaction currency UI bug',
    ],
  },
];

function RoadmapPage() {
  return (
    <MainLayout>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        margin: '0 auto',
      }}
      >
        <Card>
          <CardHeader className="justify-between">
            <div className="flex gap-5">
              {/* <Avatar isBordered radius="full" size="md" src="/avatars/avatar-1.png" /> */}
              <div className="flex flex-col gap-1 items-start justify-center">
                <h4 className="text-small font-semibold leading-none text-default-600">Last updates</h4>
              </div>
            </div>
          </CardHeader>
          <CardBody className="px-3 py-0 text-small text-default-400">
            {updates.map((update, index) => (
              <div key={index}>
                <h5 className="font-semibold text-default-600 mb-2">{update.date}</h5>
                <ul className="list-disc pl-5 mb-4">
                  {update.updates.map((_update, _index) => (
                    <li key={_index}>{_update}</li>
                  ))}
                </ul>
              </div>
            ))}
            {/* <span className="pt-2">
          #FrontendWithZoey
          <span className="py-2" aria-label="computer" role="img">
            💻
          </span>
        </span> */}
          </CardBody>
          <CardFooter className="gap-3">
            <div className="flex gap-1">
              <p className="font-semibold text-default-400 text-small">1</p>
              <p className=" text-default-400 text-small">Contributors</p>
            </div>
            <div className="flex gap-1">
              <p className="font-semibold text-default-400 text-small">{daysSinceJanFirst2024()}</p>
              <p className="text-default-400 text-small">Days of development</p>
            </div>
          </CardFooter>
        </Card>
      </div>
    </MainLayout>
  );
}

export default RoadmapPage;
