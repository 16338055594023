import { Button } from '@nextui-org/react';
import { UserIcon } from 'lucide-react';
import { useAuth } from '../layouts/MainLayout';

function ProfileHeader() {
  const { logOutMutation } = useAuth();

  return (
    <div
      style={{
        // marginTop: 40,
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
      }}
      className="w-full p-8 bg-content2 md:rounded-xl relative"
    >
      <div className="flex w-full justify-between flex-col justify-end">
        <div className="xl:flex gap-20 flex justify-center text-center">
          <div>
            <Button className="mb-2" isIconOnly>
              <UserIcon />
            </Button>
            <div className="text-xs tracking-wider text-content4 font-light">socaseinpoint</div>
          </div>
        </div>
      </div>
      <Button
        onClick={() => {
          logOutMutation.mutate();
        }}
        className="absolute right-8 top-12"
        size="sm"
      >
        Log Out
      </Button>
    </div>
  );
}

export default ProfileHeader;
