import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Spinner } from '@nextui-org/react';
import api from '../api';
import { useHttp } from '../contexts/http';
import GuestScreen from '../components/GuestScreen';

import { NODE_ENV, HOST } from '../constants';
import HeaderMobile from '../components/HeaderMobile';
import HeaderDesktop from '../components/HeaderDesktop';

const IS_DEV = NODE_ENV === 'development';

export const useAuth = () => {
  const http = useHttp();
  const queryClient = useQueryClient();

  const useAuthQuery = () => useQuery({
    queryKey: ['auth'],
    queryFn: async () => {
      const token = localStorage.getItem('token');

      if (!token) {
        return null;
      }

      try {
        return await api.user.me(token, http);
      } catch (error) {
        const newToken = localStorage.getItem('token');
        if (newToken) {
          return api.user.me(newToken, http);
        }
        return null;
      }
    },
    // onError: (error) => {
    //   // Handle error, e.g., by redirecting to login page
    // }
  });

  const logOutMutation = useMutation({
    mutationFn: async () => {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      queryClient.invalidateQueries({ queryKey: ['auth'] });
    },
  });

  return {
    useAuthQuery, logOutMutation,
  };
};

const QUOTES = [
  `Budgets aren't just numbers; they're the roadmap to financial freedom.`,
  `Every dollar planned is a step toward your dreams.`,
  `Mindful budgeting is investing in your future self.`,
  `Plan your budget, live your values, and enjoy the journey.`,
  `A budget is a plan, not a restriction. Embrace it.`,
  `Budgeting: Where small adjustments lead to big savings.`,
  `Live within your means; budget for peace of mind.`,
  `Mindful spending today is financial security tomorrow.`,
  `Your budget is your plan for success. Stick to it.`,
  `See your budget as a garden; nurture it to grow wealth.`,
];

const getRandomQuote = () => QUOTES[Math.floor(Math.random() * QUOTES.length)];

const MainLayout: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { useAuthQuery } = useAuth();

  const auth = useAuthQuery();

  if (auth.isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          height: '100vh',
          gap: 20,
          flexDirection: 'column',
        }}
        className="bg-content1"
      >
        <Spinner />
        <p>{getRandomQuote()}</p>
      </div>
    );
  }

  if (!auth.data) {
    return <GuestScreen />;
  }

  return (
    <div className="flex flex-col min-h-screen pb-24 bg-background">
      <HeaderMobile />
      <main className="w-full md:p-12">
        <div className="flex w-full">
          <div>
            <HeaderDesktop />
          </div>
          <div className="w-full">
            {children}
          </div>
        </div>
      </main>
      {IS_DEV && (
        <div style={{
          position: 'fixed',
          right: 15,
          bottom: 15,
          fontSize: 12,
        }}
        >
          Development | API:
          {' '}
          {HOST}
        </div>
      )}
    </div>
  );
};

export default MainLayout;
