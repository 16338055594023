const getNowDayMonthYear = (date = new Date()) => {
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // January is 0!
  const year = String(date.getUTCFullYear());

  return {
    day,
    month,
    year,
  };
};

export default getNowDayMonthYear;
