import MainLayout from '../layouts/MainLayout';
import TransactionsStats from '../components/TransactionsStats';

function ReportsPage() {
  return (
    <MainLayout>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
      }}
      >
        <div style={{
          width: '100%',
        }}
        >
          <TransactionsStats />
        </div>
      </div>
    </MainLayout>
  );
}

export default ReportsPage;
