import {
  Button, Checkbox, Input, ModalBody, ModalFooter, ModalHeader, Radio, RadioGroup, Textarea,
} from '@nextui-org/react';
import { FC } from 'react';
import { useFormik } from 'formik';

interface IFormInput {
  title: string,
  description: string;
  initialBalance: string;
  type: string;
  disabled: boolean;
}

const CreateCategoryForm: FC<{
  onClose: any,
  onCreate: (values: IFormInput) => void
}> = ({ onClose, onCreate }) => {
  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      initialBalance: '',
      type: 'all',
      disabled: false,
    },
    enableReinitialize: true,
    onSubmit: (values) => onCreate(values),
  });

  return (
    <div>
      <ModalHeader className="flex flex-col gap-1">Add category</ModalHeader>
      <ModalBody>
        <Input
          label="Title"
          name="title"
          onChange={formik.handleChange}
          value={formik.values.title}
        />
        <RadioGroup
          label="Type"
          color="secondary"
          name="type"
          onChange={formik.handleChange}
          value={formik.values.type}
        >
          <Radio value="all">All</Radio>
          <Radio value="income">Income</Radio>
          <Radio value="expense">Expense</Radio>
        </RadioGroup>
        <Textarea
          label="Description"
          name="description"
          onChange={formik.handleChange}
          value={formik.values.description}
        />
        <Checkbox
          name="disabled"
          color="danger"
          onChange={(e) => {
            formik.handleChange(e);
          }}
          isSelected={formik.values.disabled}
        >
          Disabled!
        </Checkbox>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" variant="light" onPress={onClose}>
          Close
        </Button>
        <Button
          color="primary"
          type="submit"
          onPress={() => {
            formik.submitForm();
            onClose();
          }}
        >
          Add
        </Button>
      </ModalFooter>
    </div>
  );
};

export default CreateCategoryForm;
