import {
  Modal, ModalBody, ModalContent, Tab, Tabs,
} from '@nextui-org/react';
import { FC } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ArrowLeftRightIcon, MoveDownRightIcon, MoveUpRightIcon,
} from 'lucide-react';
import CreateTransactionForm from './CreateTransactionForm';
import CreateExpenseForm from './CreateExpenseForm';
import { useHttp } from '../contexts/http';
import api from '../api';
import CreateTransferForm from './CreateTransferForm';

const CreateTransactionModal: FC<{
  addTransactionDisclosure: any,
}> = ({
  addTransactionDisclosure,
}) => {
  const http = useHttp();
  const queryClient = useQueryClient();

  const createIncomeMutation = useMutation({
    mutationFn: (values: any) => api.transaction.create(values, http),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['transaction.list'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.stats'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.grouped-by-category-list'] });
      // queryClient.invalidateQueries({ queryKey: ['monthly-budget.spend', currentYear, currentMonth] })
      queryClient.invalidateQueries({ queryKey: ['transaction.month-infinite'] });
    },
  });

  const createTagsMutation = useMutation({
    mutationFn: (values: any) => api.transactionTag.create(values, http),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['transaction.list'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.stats'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.grouped-by-category-list'] });
      // queryClient.invalidateQueries({ queryKey: ['monthly-budget.spend', currentYear, currentMonth] })
      queryClient.invalidateQueries({ queryKey: ['transaction.month-infinite'] });
    },
  });

  const createExpenseMutation = useMutation({
    mutationFn: (values: any) => api.transaction.createExpenses(values, http),
    onSuccess: async (values, acc) => {
      values.forEach(({
        id,
      }) => {
        createTagsMutation.mutate({
          transactionId: id,
          tags: acc.tags,
        });
      });

      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['transaction.list'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.stats'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.grouped-by-category-list'] });
      // queryClient.invalidateQueries({ queryKey: ['monthly-budget.spend', currentYear, currentMonth] })
      queryClient.invalidateQueries({ queryKey: ['transaction.month-infinite'] });
      queryClient.invalidateQueries({ queryKey: ['recurrent-budget.get'] });
      queryClient.invalidateQueries({ queryKey: ['monthly-budget.spend'] });
    },
  });

  const createTransferMutation = useMutation({
    mutationFn: (values: any) => api.transfer.create(values, http),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['transaction.list'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.stats'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.grouped-by-category-list'] });
    },
  });

  return (
    <Modal size="xl" isOpen={addTransactionDisclosure.isOpen} onOpenChange={addTransactionDisclosure.onOpenChange}>
      <ModalContent>
        {(onClose) => (
          <ModalBody>
            <Tabs
              aria-label="Options"
              color="primary"
              variant="underlined"
              classNames={{
                tabList: 'gap-6 w-full relative rounded-none p-0 border-b border-divider flex justify-center',
                cursor: 'w-full bg-warning',
                tab: 'max-w-fit px-0 h-12',
                tabContent: 'group-data-[selected=true]:text-warning',
              }}
            >
              <Tab
                key="expense"
                title={(
                  <div className="flex items-center space-x-2">
                    <MoveUpRightIcon />
                    <span>Add expense</span>
                  </div>
          )}
              >
                <CreateExpenseForm
                  onClose={onClose}
                  onCreate={(values) => {
                    createExpenseMutation.mutate(values);
                  }}
                />
              </Tab>
              <Tab
                key="income"
                title={(
                  <div className="flex items-center space-x-2">
                    <MoveDownRightIcon />
                    <span>Add income</span>
                  </div>
          )}
              >
                <CreateTransactionForm
                  onClose={onClose}
                  onCreate={(values) => {
                    createIncomeMutation.mutate(values);
                  }}
                />
              </Tab>
              <Tab
                key="transfer"
                title={(
                  <div className="flex items-center space-x-2">
                    <ArrowLeftRightIcon />
                    <span>Transfer</span>
                  </div>
          )}
              >
                <CreateTransferForm
                  onClose={onClose}
                  onCreate={(values) => {
                    createTransferMutation.mutate(values);
                  }}
                />
              </Tab>
            </Tabs>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};

export default CreateTransactionModal;
