import { Modal, ModalContent } from '@nextui-org/react';
import { FC } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import api from '../api';
import { useHttp } from '../contexts/http';
import CreateBudgetTagForm from './CreateBudgetTagForm';

const CreateBudgetTagModal: FC<{
  addBudgetTagDisclosure: any
}> = ({ addBudgetTagDisclosure }) => {
  const http = useHttp();

  // Mutations
  const createMutation = useMutation({
    mutationFn: (values: any) => api.recurrentBudgetTag.create(values, http),
    onSuccess: () => {
      // Invalidate and refetch
      // queryClient.invalidateQueries({ queryKey: ['transaction.list'] })
      // queryClient.invalidateQueries({ queryKey: ['transaction.stats'] })
      // queryClient.invalidateQueries({ queryKey: ['transaction.grouped-by-category-list'] })
    },
  });

  // query tags
  const tagsQuery = useQuery({ queryKey: ['tags.list'], queryFn: () => api.tag.list(http) });

  const tags = tagsQuery.data;

  if (!tags) {
    return null;
  }

  return (
    <Modal size="xl" isOpen={addBudgetTagDisclosure.isOpen} onOpenChange={addBudgetTagDisclosure.onOpenChange}>
      <ModalContent>
        {(onClose) => (
          <CreateBudgetTagForm
            tags={tags}
            onClose={onClose}
            onCreate={(values) => {
              createMutation.mutate(values);
            }}
          />
        )}
      </ModalContent>
    </Modal>
  );
};

export default CreateBudgetTagModal;
