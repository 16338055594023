import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Popover, PopoverContent, PopoverTrigger,
} from '@nextui-org/react';
import api from '../api';
import { useHttp } from '../contexts/http';
import getCurrency from '../helpers/getCurrency';
import getNowDayMonthYear from '../helpers/getNowDayMonthYear';
import DashboardTitleSettingsForm from './DashboardTitleSettingsForm';
import getMonthNameByNumber from '../helpers/getMonthName';

const now = getNowDayMonthYear();

const DashboardTitle = () => {
  const http = useHttp();
  const transactionsStatsQuery = useQuery({ queryKey: ['transaction.stats'], queryFn: () => api.transaction.stats(http) });

  const accountsQuery = useQuery({ queryKey: ['account.list'], queryFn: () => api.account.list(http) });

  const stats = transactionsStatsQuery.data;

  const totalAccountTotalBalanceQuery = useQuery({ queryKey: ['account-balance.getTotalAccountBalance'], queryFn: () => api.accountBalance.getTotal(http) });
  const totalBalance = totalAccountTotalBalanceQuery.data;

  const [totalBalanceAccountType, setTotalBalanceAccountType] = useState<'all' | 'internal'>('internal');

  const accounts = accountsQuery.data;

  const totalBalanceAccountTypeLabels = {
    all: 'All Accounts',
    internal: 'Internal only',
  };

  const balanceProgress = stats && Number(
    (Number(stats.currentYearIncomes.amount) - Number(stats.currentYearExpenses.amount)).toFixed(2),
  );

  return (
    <div>
      <p className="hidden md:flex text-lg font-bold tracking-wider">
        Dashboard
      </p>
      <p className="mb-4 hidden md:flex text-sm text-content4 tracking-wider">
        {now.day}
        {' '}
        {getMonthNameByNumber(new Date().getMonth() + 1)}
        {' '}
        {now.year}
      </p>
      <div
        style={{
          // marginTop: 40,
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'column',
        }}
        className="w-full p-6 bg-content2 md:rounded-t-xl md:rounded-b-xl md:w-auto md:p-3 md:pl-5 rounded-b-xl bg-gradient-to-t from-content1"
      >
        <div className="flex w-full justify-between flex-col justify-center">
          <div className="xl:flex gap-20 text-center md:text-left">
            {totalBalance && (
              <Popover placement="bottom" showArrow offset={10}>
                <PopoverTrigger>
                  <div className="flex flex-col w-full">
                    <p className="font-bold text-2xl tracking-wider mb-1">
                      {getCurrency(String(totalBalance.reduce((acc: any, {
                        usdBalance,
                        id,
                      }) => {
                        const account = accounts && accounts.find((a: any) => a.id === id);
                        if (totalBalanceAccountType === 'internal' && account?.type === 'external') return acc;

                        return acc + Number(usdBalance);
                      }, 0).toFixed(2)), 'USD')}
                    </p>
                    <p className="text-sm tracking-wide font-light text-xs tracking-wider text-content4 flex justify-center md:justify-start">
                      Total balance (
                      {
                        totalBalanceAccountTypeLabels[totalBalanceAccountType]
                      }
                      )
                    </p>
                  </div>
                </PopoverTrigger>
                <PopoverContent className="w-[240px]">
                  {(titleProps) => (
                    <div className="px-1 py-2 w-full">
                      <p className="text-small font-bold text-foreground" {...titleProps}>
                        Balance settings
                      </p>
                      <div className="mt-2 flex flex-col gap-2 w-full">
                        <DashboardTitleSettingsForm
                          totalBalanceAccountType={totalBalanceAccountType}
                          setTotalBalanceAccountType={setTotalBalanceAccountType}
                        />
                      </div>
                    </div>
                  )}
                </PopoverContent>
              </Popover>
            )}
          </div>
        </div>
      </div>
      <div className="p-4 flex gap-4 flex-col md:p-0 md:mt-2 bg-background md:bg-transparent">
        {stats && (
          <div className="flex flex-col gap-2 sm:flex-row md:flex-col">
            <div className="bg-content1 p-3 pl-5 pr-5 rounded-xl sm:w-1/3 md:w-full">
              <p className="font-bold text-2xl tracking-wide mb-1">{getCurrency(String(stats.currentYearExpenses.amount), 'USD')}</p>
              <p className="text-sm tracking-wide font-light text-xs text-content4">Total Expense</p>
            </div>
            <div className="bg-content1 p-3 pl-5 pr-5 rounded-xl sm:w-1/3 md:w-full">
              <p className="font-bold text-2xl tracking-wide mb-1">{getCurrency(String(stats.currentYearIncomes.amount), 'USD')}</p>
              <p className="text-sm tracking-wide font-light text-xs text-content4">Total Income</p>
            </div>
            <div className="bg-content1 p-3 pl-5 pr-5 rounded-xl sm:w-1/3 md:w-full">
              <p className="font-bold text-2xl tracking-wide mb-1">{getCurrency(String(balanceProgress), 'USD')}</p>
              <p className="text-sm tracking-wide font-light text-xs text-content4">Balance Progress</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardTitle;
