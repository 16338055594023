function getPreviousDay(inputDate?: string) {
  // Create a date object from the input date
  const date = new Date(inputDate || '');

  // Subtract one day from the date
  date.setDate(date.getUTCDate() + 1);

  return date;
}

export default getPreviousDay;
