function getMonthNameByNumber(monthNumber: number): string {
  if (monthNumber < 1 || monthNumber > 12) {
    throw new Error('Invalid month number. Must be between 0 and 11.');
  }

  const monthNames: string[] = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];

  return monthNames[monthNumber - 1];
}

export default getMonthNameByNumber;
