import React, { FC, createContext, useMemo } from 'react';
import { useDisclosure } from '@nextui-org/react';
import CreateTransactionModal from './CreateTransactionModal';
import CreateBudgetModal from './CreateBudgetModal';
import CreateBudgetTagModal from './CreateBudgetTagModal';
import ConfirmBudgetModal from './ConfirmBudgetModal';
import SyncAccountBalancesModal from './SyncAccountBalancesModal';

export const ModalContext = createContext<any>(null);

const Modals: FC<{
  children: React.ReactNode;
}> = ({
  children,
}) => {
  const addTransactionDisclosure = useDisclosure();
  const addBudgetDisclosure = useDisclosure();
  const addBudgetTagDisclosure = useDisclosure();
  const confirmBudgetDisclosure = useDisclosure();
  const syncAccountBalancesDisclosure = useDisclosure();

  const disclosures = useMemo(() => ({
    addTransactionDisclosure,
    addBudgetDisclosure,
    addBudgetTagDisclosure,
    confirmBudgetDisclosure,
    syncAccountBalancesDisclosure,
  }), [
    addTransactionDisclosure,
    addBudgetDisclosure,
    addBudgetTagDisclosure,
    confirmBudgetDisclosure,
    syncAccountBalancesDisclosure,
  ]);

  return (
    <ModalContext.Provider value={disclosures}>
      {children}
      <CreateTransactionModal
        addTransactionDisclosure={disclosures.addTransactionDisclosure}
      />
      <CreateBudgetModal addBudgetDisclosure={disclosures.addBudgetDisclosure} />
      <CreateBudgetTagModal addBudgetTagDisclosure={disclosures.addBudgetTagDisclosure} />
      <ConfirmBudgetModal addBudgetDisclosure={disclosures.confirmBudgetDisclosure} />
      <SyncAccountBalancesModal syncAccountBalancesDisclosure={disclosures.syncAccountBalancesDisclosure} />
    </ModalContext.Provider>
  );
};

export default Modals;
