import { FC, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Button, Popover, PopoverContent, PopoverTrigger,
} from '@nextui-org/react';
import { CoinsIcon, RefreshCwIcon } from 'lucide-react';
import api from '../api';
import { useHttp } from '../contexts/http';
import getCurrency from '../helpers/getCurrency';
import DashboardTitleSettingsForm from './DashboardTitleSettingsForm';

const TotalBalance: FC<{
  syncAccountBalancesDisclosure: any;
  setIsDisplayBalanceInAccountCurrency: any;
  isDisplayBalanceInAccountCurrency: any;
}> = ({
  syncAccountBalancesDisclosure,
  setIsDisplayBalanceInAccountCurrency,
  isDisplayBalanceInAccountCurrency,
}) => {
  const http = useHttp();
  // const transactionsStatsQuery = useQuery({ queryKey: ['transaction.stats'], queryFn: () => api.transaction.stats(http) });

  const accountsQuery = useQuery({ queryKey: ['account.list'], queryFn: () => api.account.list(http) });

  // const stats = transactionsStatsQuery.data;

  const totalAccountTotalBalanceQuery = useQuery({ queryKey: ['account-balance.getTotalAccountBalance'], queryFn: () => api.accountBalance.getTotal(http) });
  const totalBalance = totalAccountTotalBalanceQuery.data;

  const [totalBalanceAccountType, setTotalBalanceAccountType] = useState<'all' | 'internal'>('internal');

  const accounts = accountsQuery.data;

  const totalBalanceAccountTypeLabels = {
    all: 'Accounts and external',
    internal: 'Internal only',
  };

  // const balanceProgress = stats && Number((Number(stats.currentYearIncomes.amount) - Number(stats.currentYearExpenses.amount)).toFixed(2));

  return (
    <div>
      <div
        style={{
          // marginTop: 40,
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'column',
        }}
        className="w-full p-8 bg-content2 md:rounded-xl relative"
      >
        <div className="flex w-full justify-between flex-col justify-end">
          <div className="xl:flex gap-20 flex justify-center text-center">
            {totalBalance && (
              <div>
                <Popover placement="bottom" showArrow offset={10}>
                  <PopoverTrigger>
                    <div>
                      <p className="font-bold text-2xl tracking-wider mb-1">
                        {getCurrency(String(totalBalance.reduce((acc: any, {
                          usdBalance,
                          id,
                        }) => {
                          const account = accounts && accounts.find((a: any) => a.id === id);
                          if (totalBalanceAccountType === 'internal' && account?.type === 'external') return acc;

                          return acc + Number(usdBalance);
                        }, 0).toFixed(2)), 'USD')}
                      </p>
                      <p className="text-sm tracking-wide font-light text-xs tracking-wider text-content4 flex justify-center md:justify-start">
                        Total balance (
                        {
                          totalBalanceAccountTypeLabels[totalBalanceAccountType]
                        }
                        )
                      </p>
                    </div>
                  </PopoverTrigger>
                  <PopoverContent className="w-[240px]">
                    {(titleProps) => (
                      <div className="px-1 py-2 w-full">
                        <p className="text-small font-bold text-foreground" {...titleProps}>
                          Balance settings
                        </p>
                        <div className="mt-2 flex flex-col gap-2 w-full">
                          <DashboardTitleSettingsForm
                            totalBalanceAccountType={totalBalanceAccountType}
                            setTotalBalanceAccountType={setTotalBalanceAccountType}
                          />
                        </div>
                      </div>
                    )}
                  </PopoverContent>
                </Popover>
              </div>
            )}
          </div>
          <Button
            className="absolute left-10"
            isIconOnly
            size="lg"
            color="default"
            variant="ghost"
            onPress={() => {
              setIsDisplayBalanceInAccountCurrency(!isDisplayBalanceInAccountCurrency);
            }}
          >
            <CoinsIcon />
          </Button>
          <Button className="absolute right-10" isIconOnly size="lg" color="default" variant="ghost" onPress={syncAccountBalancesDisclosure.onOpen}>
            <RefreshCwIcon />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TotalBalance;
