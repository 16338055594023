import { useEffect } from 'react';
import { useFormik } from 'formik';
import {
  Input, Button, ModalHeader, ModalBody, ModalFooter,
} from '@nextui-org/react';
import { useQuery } from '@tanstack/react-query';
import { useHttp } from '../contexts/http';
import api from '../api';
import { Account } from '../types';
import getCurrency from '../helpers/getCurrency';
import getDate from '../helpers/getDate';

function getInitialAccountValues(accounts: Account[], accountBalances: any = []) {
  return accounts.map((account) => ({
    id: account.id,
    balance: accountBalances.find((ab: any) => ab.id === account.id)?.balance || '0',
  }));
}

function SyncAccountBalancesForm({ onClose }: {
  onClose: () => void
}) {
  const http = useHttp();
  const now = new Date();
  const { data: accounts, isLoading } = useQuery({ queryKey: ['account.list'], queryFn: () => api.account.list(http) });

  const accountBalances = useQuery({ queryKey: ['account-balance.getTotalAccountBalance'], queryFn: () => api.accountBalance.getTotal(http) }).data;

  // const { data: accountBalance } = useQuery({ queryKey: ['account-balance.list'], queryFn: () => api.accountBalance.list(null, http) });

  const formik = useFormik({
    initialValues: {
      accounts: accounts ? getInitialAccountValues(accounts, accountBalances) : [],
      day: now.getDate().toString(),
      month: (now.getMonth() + 1).toString(), // Months are 0-indexed
      year: now.getFullYear().toString(),
    },
    onSubmit: async (values) => {
      try {
        const response = api.accountBalance.create({
          accounts: values.accounts,
          confirmedAt: `${values.year}-${values.month.padStart(2, '0')}-${values.day.padStart(2, '0')}`,
        }, http);
        console.log('Success:', response);
        // Handle success
      } catch (error) {
        console.error('Error:', error);
        // Handle error
      }
    },
  });

  useEffect(() => {
    if (accounts) {
      formik.setValues({
        ...formik.values, accounts: getInitialAccountValues(accounts, accountBalances),
      });
    }
  }, [accounts, accountBalances]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // const externalAccounts = accounts && accounts.filter((acc) => acc.type === 'external');

  return (
    <div>
      <ModalHeader className="flex flex-col gap-1">Sync account balance</ModalHeader>
      <ModalBody>
        {/* {accountBalance && accountBalance.data.map((ab) => {
          return (
            <div>{ab.id}</div>
          )
        })} */}
        <div style={{
          height: 300, overflowY: 'scroll',
        }}
        >
          {accounts && accounts
            // .filter((acc) => acc.type === 'internal')
            .map((account, index) => {
              const accountBalance = accountBalances?.find((ab) => ab.id === account.id);
              const value = accountBalance ? getCurrency(accountBalance.balance, account.currencyCode) : getCurrency('0', account.currencyCode);

              return (
                <div key={account.id} style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                  >
                    <span>{account.title}</span>
                    <span>{value}</span>
                  </div>
                  <Input
                    label={`Balance for ${account.title}`}
                    name={`accounts[${index}].balance`}
                    onChange={formik.handleChange}
                    value={formik.values.accounts[index]?.balance || ''}
                    style={{ width: 300, marginRight: 10 }}
                  />
                  <span style={{
                    fontSize: '0.8rem',
                    marginBottom: 20,
                    textAlign: 'right',
                  }}
                  >
                    Last sync:
                    {' '}
                    {accountBalance ? getDate(accountBalance.confirmedAt) : 'Never'}
                  </span>
                </div>
              );
            })}
          {/* {!!externalAccounts?.length && (
            <>
              <p className="text-sm text-default" style={{
                marginBottom: 10
              }}>External accounts</p>
              {externalAccounts.map((account, index) => (
                <div key={account.id} style={{ display: 'flex', flexDirection: 'row' }}>
                  <Input
                    label={`Balance for ${account.title}`}
                    name={`accounts[${index}].balance`}
                    onChange={formik.handleChange}
                    value={formik.values.accounts[index]?.balance || ''}
                    style={{ width: 300, marginRight: 10 }}
                  />
                </div>
              ))}</>
          )} */}
        </div>
        <div style={{
          display: 'flex', flexDirection: 'row', gap: '10px', marginTop: '10px',
        }}
        >
          <Input
            label="Day"
            name="day"
            onChange={formik.handleChange}
            value={formik.values.day}
            style={{ width: 100 }}
          />
          <Input
            label="Month"
            name="month"
            onChange={formik.handleChange}
            value={formik.values.month}
            style={{ width: 100 }}
          />
          <Input
            label="Year"
            name="year"
            onChange={formik.handleChange}
            value={formik.values.year}
            style={{ width: 100 }}
          />
        </div>

      </ModalBody>
      <ModalFooter>
        <Button color="danger" variant="light" onPress={onClose}>
          Close
        </Button>
        <Button
          color="primary"
          type="submit"
          onPress={() => {
            formik.submitForm();
            onClose();
          }}
        >
          Confirm
        </Button>
      </ModalFooter>
    </div>
  );
}

export default SyncAccountBalancesForm;
