import { Wallet2Icon } from 'lucide-react';
import { Button } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import ProfileHeader from '../components/ProfileHeader';
import MainLayout from '../layouts/MainLayout';

function ProfilePage() {
  const navigate = useNavigate();

  const navigateToAccounts = () => {
    navigate('/');
  };

  const navigateToRoadmap = () => {
    navigate('/roadmap');
  };

  const navigateToCategoriesTags = () => {
    navigate('/categories-tags');
  };

  return (
    <MainLayout>
      <div className="">
        <ProfileHeader />
      </div>
      <div className="flex flex-wrap justify-center max-w-[400px] m-auto md:max-w-max">
        <Button
          onClick={() => {
            navigateToAccounts();
          }}
          className="md:w-40 h-40 flex items-center justify-center flex-col bg-transparent w-1/2"
        >
          <Wallet2Icon />
          <p className="font-bold tracking-wider text-md h-2 mb-2">Accounts</p>
          <p className="font-light tracking-wider text-xs text-content4 font-light h-2">Manage Accounts</p>
        </Button>
        <Button
          onClick={() => {
            navigateToCategoriesTags();
          }}
          className="md:w-40 h-40 flex items-center justify-center flex-col bg-transparent w-1/2"
        >
          <Wallet2Icon />
          <p className="font-bold tracking-wider text-md h-2 mb-2">Categories</p>
          <p className="font-light tracking-wider text-xs text-content4 font-light h-2">
            Categorize
            expenses
            <div>Incomes</div>
          </p>
        </Button>
        <Button
          className="md:w-40 h-40 flex items-center justify-center flex-col bg-transparent w-1/2"
        >
          <Wallet2Icon />
          <p className="font-bold tracking-wider text-md h-2 mb-2">Rec. Trans.</p>
          <p className="font-light tracking-wider text-xs text-content4 font-light h-2">
            advance
            management
          </p>
        </Button>
        <Button
          onClick={() => {
            navigateToCategoriesTags();
          }}
          className="md:w-40 h-40 flex items-center justify-center flex-col bg-transparent w-1/2"
        >
          <Wallet2Icon />
          <p className="font-bold tracking-wider text-md h-2 mb-2">Tags</p>
          <p className="font-light tracking-wider text-xs text-content4 font-light h-2">
            Tag
            Transactions
          </p>
        </Button>
        <Button
          className="md:w-40 h-40 flex items-center justify-center flex-col bg-transparent w-1/2"
        >
          <Wallet2Icon />
          <p className="font-bold tracking-wider text-md h-2 mb-2">
            Balance
            History
          </p>
          <p className="font-light tracking-wider text-xs text-content4 font-light h-2">Advanced stats</p>
        </Button>
        <Button
          className="md:w-40 h-40 flex items-center justify-center flex-col bg-transparent w-1/2"
        >
          <Wallet2Icon />
          <p className="font-bold tracking-wider text-md h-2 mb-2">Budgets</p>
          <p className="font-light tracking-wider text-xs text-content4 font-light h-2">Plan Your future</p>
        </Button>
        <Button
          className="md:w-40 h-40 flex items-center justify-center flex-col bg-transparent w-1/2"
        >
          <Wallet2Icon />
          <p className="font-bold tracking-wider text-md h-2 mb-2">
            Balance
            History
          </p>
          <p className="font-light tracking-wider text-xs text-content4 font-light h-2">Advanced stats</p>
        </Button>
        <Button
          onClick={() => {
            navigateToRoadmap();
          }}
          className="md:w-40 h-40 flex items-center justify-center flex-col bg-transparent w-1/2"
        >
          <Wallet2Icon />
          <p className="font-bold tracking-wider text-md h-2 mb-2">Roadmap</p>
          <p className="font-light tracking-wider text-xs text-content4 font-light h-2">FAQ</p>
        </Button>
      </div>
    </MainLayout>
  );
}

export default ProfilePage;
