import {
  Button, Input, ModalBody, ModalFooter, ModalHeader,
} from '@nextui-org/react';
import { FC } from 'react';
import { useFormik } from 'formik';
import { CirclePicker } from 'react-color';

interface IFormInput {
  title: string,
  color: string,
}

const CreateTagForm: FC<{
  onClose: any,
  onCreate: (values: IFormInput) => void
}> = ({ onClose, onCreate }) => {
  const formik = useFormik({
    initialValues: {
      title: '',
      color: '00bcd4',
    },
    enableReinitialize: true,
    onSubmit: (values) => onCreate(values),
  });

  return (
    <div>
      <ModalHeader className="flex flex-col gap-1">Add tag</ModalHeader>
      <ModalBody>
        <Input
          label="Title"
          name="title"
          onChange={formik.handleChange}
          value={formik.values.title}
        />
        <div style={{
          margin: '15px auto 10px',
        }}
        >
          <CirclePicker
            color={formik.values.color}
            onChangeComplete={(color) => formik.setFieldValue('color', color.hex.slice(1))}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" variant="light" onPress={onClose}>
          Close
        </Button>
        <Button
          color="primary"
          type="submit"
          onPress={() => {
            formik.submitForm();
            onClose();
          }}
        >
          Add
        </Button>
      </ModalFooter>
    </div>
  );
};

export default CreateTagForm;
