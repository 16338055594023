import MonthlyBudget from '../components/MonthlyBudget';
import MainLayout from '../layouts/MainLayout';

function MonthlyBudgetPage() {
  return (
    <MainLayout>
      <MonthlyBudget />
    </MainLayout>
  );
}

export default MonthlyBudgetPage;
