import CategoryList from '../components/CategoryList';
import MainLayout from '../layouts/MainLayout';
import TagList from '../components/TagList';

function CategoriesPage() {
  return (
    <MainLayout>
      {/* <div style={{
        display: 'flex',
        flexDirection: 'row',
      }}>
        <div style={{
          width: '100%',
        }}>
          <TransactionsStats />
        </div>
        <TransactionsStats />
      </div> */}
      <div className="">
        <div className="mb-10">
          <CategoryList />
        </div>
        <TagList />
      </div>
    </MainLayout>
  );
}

export default CategoriesPage;
