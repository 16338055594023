export const currencies = [{
  id: 'USD',
  title: 'USD',
}, {
  id: 'EUR',
  title: 'Euro',
}, {
  id: 'TRY',
  title: 'Turkish Lira',
}, {
  id: 'RSD',
  title: 'Serbian Dinar',
}, {
  id: 'RUB',
  title: 'Russian ruble',
}, {
  id: 'CLP',
  title: 'Chilean peso',
}];

// eslint-disable-next-line no-undef
export const HOST = process.env.REACT_APP_API || 'http://127.0.0.1:8080';

// eslint-disable-next-line no-undef
export const NODE_ENV = process.env.NODE_ENV || 'production';
