function getPreviousMonthAndYear() {
  // Create a date object for the current date and time
  const currentDate = new Date();

  // Get the current month (0-11, where 0 is January and 11 is December)
  const currentMonth = currentDate.getUTCMonth();

  // Get the current year
  const currentYear = currentDate.getUTCFullYear();

  // Calculate the previous month. If the current month is January (0),
  // set the month to December (11) and decrement the year.
  let previousMonth; let
    previousYear;
  if (currentMonth === 0) { // January
    previousMonth = 11; // December
    previousYear = currentYear - 1;
  } else {
    previousMonth = currentMonth - 1;
    previousYear = currentYear;
  }

  // Return the year and month of the previous month
  return {
    year: previousYear, month: previousMonth + 1,
  }; // +1 to convert month from 0-11 to 1-12 format
}

export default getPreviousMonthAndYear;
