import { Modal, ModalContent } from '@nextui-org/react';
import { FC } from 'react';
import EditTransactionForm from './EditTransactionForm';

const EditTransactionModal: FC<{
  selectedTransactionId: string
  isUpdateTransactionModalOpen: boolean
  onUpdateTransactionModalOpenChange: any
  updateTransactionMutation: any
  deleteTransactionMutation: any
}> = ({
  selectedTransactionId,
  isUpdateTransactionModalOpen,
  onUpdateTransactionModalOpenChange,
  updateTransactionMutation,
  deleteTransactionMutation,
}) => (
  <Modal isOpen={isUpdateTransactionModalOpen} onOpenChange={onUpdateTransactionModalOpenChange}>
    <ModalContent>
      {(onClose) => (
        <EditTransactionForm
          id={selectedTransactionId}
          onClose={onClose}
          onUpdate={(values) => updateTransactionMutation.mutate(values)}
          onDelete={deleteTransactionMutation.mutate}
        />
      )}
    </ModalContent>
  </Modal>
);

export default EditTransactionModal;
