import { Button } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import CategoryView from '../components/CategoryView';
import MainLayout from '../layouts/MainLayout';

function CategoryPage() {
  // function to get back on '/' through router
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <MainLayout>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
      }}
      >
        <div style={{
          width: '100%',
          paddingTop: 20,
        }}
        >
          <div>
            <Button onClick={handleBackClick}>
              Get Back
            </Button>
          </div>
          <CategoryView />
        </div>
      </div>
    </MainLayout>
  );
}

export default CategoryPage;
