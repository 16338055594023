import React, { FC, useState } from 'react';
import {
  Button, Modal, ModalContent, Tab, Tabs, useDisclosure,
} from '@nextui-org/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import CreateCategoryForm from './CreateCategoryForm';
import CategoryButton from './CategoryButton';
import EditCategoryForm from './EditCategoryForm';
import { useHttp } from '../contexts/http';
import UITitle from './UITitle';

const CategoriesList: FC = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { isOpen: isUpdateModalOpen, onOpen: onUpdateModalOpen, onOpenChange: onUpdateModalOpenChange } = useDisclosure();
  const [selectedCategoryId, setSelectedCategoryId] = React.useState<string | null>(null);
  const http = useHttp();

  // Access the client
  const queryClient = useQueryClient();

  // Queries
  const query = useQuery({ queryKey: ['category.list'], queryFn: () => api.category.list(http) });

  // Mutations
  const createMutation = useMutation({
    mutationFn: (values: any) => api.category.create(values, http),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['category.list'] });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id: string) => api.category.delete(id, http),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['category.list'] });
    },
  });

  const updateMutation = useMutation({
    mutationFn: (values: any) => api.category.update(values, http),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['category.list'] });
    },
  });

  const categories = query.data;

  const selectedCategory = categories?.find((_category) => _category.id === selectedCategoryId);

  const [catType, setCatType] = useState('all');

  const navigate = useNavigate();

  return (
    <div>
      <div style={{
        margin: '0',
      }}
      >
        <UITitle>
          CATEGORIES
        </UITitle>
        <div style={{
          display: 'flex',
          margin: '10px 0 20px',
          gap: 10,
          flexWrap: 'wrap',
        }}
        >
          <Tabs
            selectedKey={catType}
            onSelectionChange={(key: any) => {
              setCatType(key);
            }}
            className="mb-2"
            variant="bordered"
            aria-label="Tabs variants"
          >
            <Tab key="all" title="All" />
            <Tab key="expense" title="Expenses" />
            <Tab key="income" title="Incomes" />
          </Tabs>
          <div style={{
            width: '100%',
            gap: 10,
            display: 'flex',
            flexDirection: 'column',
          }}
          >
            {query.data && query.data
              .filter((cat) => {
                if (catType === 'expense') {
                  return cat.type === 'expense' || cat.type === 'all';
                }

                if (catType === 'income') {
                  return cat.type === 'income' || cat.type === 'all';
                }

                return true;
              })
              .map((category) => (
                <CategoryButton
                  key={category.id}
                  icon={category.icon || 'default'}
                  title={category.title}
                  onClick={() => {
                    navigate(`/category/${category.id}`);
                  }}
                  onEditClick={() => {
                    setSelectedCategoryId(category.id);
                    onUpdateModalOpen();
                  }}
                />
              ))}
          </div>
        </div>
        <Button color="default" variant="ghost" onPress={onOpen}>Add category</Button>
      </div>

      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <CreateCategoryForm onClose={onClose} onCreate={createMutation.mutate} />
          )}
        </ModalContent>
      </Modal>
      {selectedCategory && (
        <Modal isOpen={isUpdateModalOpen} onOpenChange={onUpdateModalOpenChange}>
          <ModalContent>
            {(onClose) => (
              <EditCategoryForm
                id={selectedCategory.id}
                category={selectedCategory}
                onClose={onClose}
                onUpdate={updateMutation.mutate}
                onDelete={deleteMutation.mutate}
              />
            )}
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};

export default CategoriesList;
