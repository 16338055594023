import {
  Button, ModalBody, ModalFooter, ModalHeader, Progress,
} from '@nextui-org/react';
import { FC } from 'react';
import { useFormik } from 'formik';
import { useQuery } from '@tanstack/react-query';
import { Fireworks } from '@fireworks-js/react';
import api from '../api';
import { useHttp } from '../contexts/http';
import priceWithCurrency from '../helpers/priceWithCurrency';
import getMonthNameByNumber from '../helpers/getMonthName';

const ConfirmBudgetForm: FC<{
  onClose: any,
  onCreate: (values: any) => void
  month: number,
  year: number,
}> = ({
  onClose, onCreate, month, year,
}) => {
  const http = useHttp();

  const previousMonthExpenseQuery = useQuery({ queryKey: ['confirm-budget.previous-month-expenses'], queryFn: () => api.transaction.getExpensesByMonth(month, year, http) });

  const recentBydgetQuery = useQuery({ queryKey: ['recurrent-budget.get'], queryFn: () => api.budget.get(http) });

  const monthlyBudget = recentBydgetQuery?.data?.find((budget) => budget.period === 'monthly');

  // const transactionsStatsQuery = useQuery({ queryKey: ['transaction.stats'], queryFn: () => api.transaction.stats(http) });

  // const stats = transactionsStatsQuery.data;

  const categoriesQuery = useQuery({ queryKey: ['category.list'], queryFn: () => api.category.list(http) });
  const accountsQuery = useQuery({ queryKey: ['account.list'], queryFn: () => api.account.list(http) });

  const categories = categoriesQuery.data;
  const accounts = accountsQuery.data;

  const formik = useFormik({
    initialValues: {
    },
    enableReinitialize: true,
    onSubmit: (values) => onCreate(values),
  });

  if (!accounts || !categories) {
    return null;
  }

  const previousMonthExpense = previousMonthExpenseQuery.data;

  const percentsLeft = previousMonthExpense ? ((Number(previousMonthExpense?.amount) / Number(previousMonthExpense.amount)) * 100) : 0;

  if (formik.submitCount > 0) {
    return (
      <div>
        <ModalHeader className="flex flex-col gap-1">
          Confirm
          {getMonthNameByNumber(month)}
          {' '}
          Monthly Budget
        </ModalHeader>
        <ModalBody>
          <div>
            <div>
              {getMonthNameByNumber(month)}
              {' '}
              Balance confirmed
            </div>
            <Fireworks
              style={{
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                position: 'absolute',
              }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type="submit"
            onPress={() => {
              onClose();
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </div>
    );
  }

  return (
    <div>
      <ModalHeader className="flex flex-col gap-1">
        Confirm
        {getMonthNameByNumber(month)}
        {' '}
        Monthly Budget
      </ModalHeader>
      <ModalBody>
        <Progress
          isStriped
          aria-label="Loading..."
          size="lg"
          color={percentsLeft < 80 ? 'primary' : 'danger'}
          value={percentsLeft}
          className="max-w-md w-full"
          radius="full"
          classNames={{
            base: 'max-w-none',
            track: 'h-7',
          }}
        />
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          marginTop: 20,
          fontSize: 12,
        }}
        >
          <div>
            {priceWithCurrency(Number(
              Math.round((Number(monthlyBudget?.usdBudget) - Number(previousMonthExpense?.amount)) * 100) / 100,
            ))}
            {' '}
            LEFT
          </div>
          <div>
            OUT OF
            {priceWithCurrency(Number(monthlyBudget?.usdBudget))}
          </div>
        </div>
        <div style={{
          display: 'flex',
          gap: 10,
        }}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="danger" variant="light" onPress={onClose}>
          Close
        </Button>
        <Button
          color="primary"
          type="submit"
          onPress={() => {
            formik.submitForm();
          }}
        >
          Confirm
        </Button>
      </ModalFooter>
    </div>
  );
};

export default ConfirmBudgetForm;
