function getDate(_date: string | Date) {
  const date = new Date(_date);
  // Use getUTCDate, getUTCMonth, and getUTCFullYear to ensure UTC is used.
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1; // getUTCMonth returns 0-11, so add 1
  const year = date.getUTCFullYear();

  // Create a date string in the format "12 May 2024"
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'];
  return `${day} ${monthNames[month - 1]} ${year}`;
}

export default getDate;
