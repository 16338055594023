// AccountButton.tsx
import { TagIcon } from 'lucide-react';
import React from 'react';
import Color from 'color';

interface TagButtonProps {
  title: string;
  color: string;
  onClick: () => void;
}

const TagButton: React.FC<TagButtonProps> = ({ title, color, onClick }) => (
  <div style={{
    display: 'flex',
    gap: 10,
  }}
  >
    <button
      type="button"
      onClick={onClick}
      style={{
        // position: 'relative',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        textAlign: 'left',
      }}
    >
      <TagIcon
        fill={`#${color}`}
        className="h-6 w-6 text-default-900 dark:text-default-900"
        color={`${Color(`#${color}`).lighten(0.9).hex()}`}
      />
      <div>
        <p
          className="text-sm text-default-900 dark:text-default-900"
        >
          {title}
        </p>
      </div>
    </button>
  </div>
);

export default TagButton;
