import { useContext } from 'react';
import { ModalContext } from '../components/Modals';

const useModals = () => {
  const modalContext = useContext(ModalContext);
  return {
    modalContext,
  };
};

export default useModals;
