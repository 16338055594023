import { Modal, ModalContent } from '@nextui-org/react';
import { FC } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import CreateBudgetForm from './CreateBudgetForm';
import api from '../api';
import { useHttp } from '../contexts/http';

const CreateBudgetModal: FC<{
  addBudgetDisclosure: any
}> = ({ addBudgetDisclosure }) => {
  const http = useHttp();

  // Access the client
  const queryClient = useQueryClient();

  // Mutations
  const createMutation = useMutation({
    mutationFn: (values: any) => api.budget.create(values, http),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['transaction.list'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.stats'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.grouped-by-category-list'] });
    },
  });

  return (
    <Modal isOpen={addBudgetDisclosure.isOpen} onOpenChange={addBudgetDisclosure.onOpenChange}>
      <ModalContent>
        {(onClose) => (
          <CreateBudgetForm
            onClose={onClose}
            onCreate={(values) => {
              createMutation.mutate(values);
            }}
          />
        )}
      </ModalContent>
    </Modal>
  );
};

export default CreateBudgetModal;
