import { Button } from '@nextui-org/react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { ResponsiveBar } from '@nivo/bar';
import api from '../api';
import { useHttp } from '../contexts/http';
import CategoryIcon from './CategoryIcon';
import CategoryTransactionsTable from './CategoryTransactionsTable';

function CategoryView() {
  const http = useHttp();

  const tooltipText = document.documentElement.classList.contains('dark') ? 'white' : '#161618'; // TODO: refactor
  const tooltipBackground = document.documentElement.classList.contains('dark') ? '#161618' : 'white'; // TODO: refactor

  const { id } = useParams();

  const query = useQuery({ queryKey: ['category.get'], queryFn: () => api.category.get(id || '', http) });
  const expensesByMonthQuery = useQuery({ queryKey: ['category.expensesByMonth'], queryFn: () => api.category.expensesByMonth(id || '', http) });

  const category = query.data;

  if (!category) {
    return (
      <div style={{
        width: '100%',
        paddingTop: 20,
      }}
      />
    );
  }

  return (
    <div>
      <div className="flex gap-5 mt-10 mb-10">
        <div className="mt-1">
          <CategoryIcon icon={category.icon} size="10" />
        </div>
        <div>
          <h1 className="text-4xl font-bold mb-2">{category.title}</h1>
          <p className="mb-4">{category.description}</p>
          <div className="flex gap-2 mb-2">
            <Button size="sm">
              Edit
            </Button>
            <Button size="sm">
              Delete
            </Button>
          </div>
        </div>
      </div>
      {expensesByMonthQuery.data && (
        <div className="w-[100%] h-[500px]">
          <ResponsiveBar
            colors={{ scheme: 'dark2' }}
            data={expensesByMonthQuery.data}
            keys={['total_amount']}
            indexBy="month"
            margin={{
              top: 20, right: 50, bottom: 50, left: 70,
            }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Month',
              legendPosition: 'middle',
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Total Amount',
              legendPosition: 'middle',
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            theme={{
              tooltip: {
                container: {
                  background: tooltipBackground,
                  color: tooltipText,
                  fontSize: '16px',
                  padding: '5px 10px',
                },
              },
            }}
            animate
          />
        </div>
      )}
      <CategoryTransactionsTable
        categoryId={category.id}
      />
    </div>
  );
}

export default CategoryView;
