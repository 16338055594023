import React from 'react';
import TransactionsTable from './TransactionsTable';
import TransfersTable from './TransfersTable';

interface MonthlyBudgetTransactionsProps {
  onOpenEditTransactionModal: (id: string) => void,
  onOpenEditTransferModal: (id: string) => void,
  currentMonth: number,
  currentYear: number,
}

const MonthlyBudgetTransactions: React.FC<MonthlyBudgetTransactionsProps> = ({
  onOpenEditTransactionModal,
  onOpenEditTransferModal,
  currentMonth,
  currentYear,
}) => (
  <div className="mt-8 w-full">
    {/* <TransactionsGroupedByCategoryTable onOpenEditModal={(id: string) => {
        setSelectedCategoryId(id)
        onUpdateModalOpen()
      }} /> */}
    <TransactionsTable
      currentMonth={currentMonth}
      currentYear={currentYear}
      onOpenEditTransactionModal={onOpenEditTransactionModal}
    />
    <TransfersTable
      currentMonth={currentMonth}
      currentYear={currentYear}
      onOpenEditTransferModal={onOpenEditTransferModal}
    />
  </div>
);

export default MonthlyBudgetTransactions;
