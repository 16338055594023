import React, { FC, useEffect } from 'react';
import { useAuth } from '../layouts/MainLayout';

const AuthTheme: FC<{
  children: React.ReactNode
}> = ({
  children,
}) => {
  const { useAuthQuery } = useAuth();

  const auth = useAuthQuery();

  useEffect(() => {
    if (auth.data?.theme) {
      document.documentElement.classList.add(auth.data?.theme);
    }
  }, [auth.data?.theme]);

  return (
    <div>
      {children}
    </div>
  );
};

export default AuthTheme;
