import {
  Button, Input, ModalBody, ModalFooter, Select, SelectItem, Textarea,
} from '@nextui-org/react';
import { FC } from 'react';
import { useFormik } from 'formik';
import { useQuery } from '@tanstack/react-query';
import api from '../api';
import { currencies } from '../constants';
import { useHttp } from '../contexts/http';
import getNowDayMonthYear from '../helpers/getNowDayMonthYear';

interface IFormInput {
  amount: string;
  tax: string;
  day: string;
  month: string;
  year: string;
  fromAccountId: string;
  toAccountId: string;
  amountCurrencyCode: string;
  taxCurrencyCode: string;
  comment: string;
}

const now = getNowDayMonthYear();

const CreateTransferForm: FC<{
  onClose: any,
  onCreate: (values: IFormInput) => void
}> = ({ onClose, onCreate }) => {
  const http = useHttp();
  const categoriesQuery = useQuery({ queryKey: ['category.list'], queryFn: () => api.category.list(http) });
  const accountsQuery = useQuery({ queryKey: ['account.list'], queryFn: () => api.account.list(http) });

  const categories = categoriesQuery.data;
  const accounts = accountsQuery.data;

  const formik = useFormik({
    initialValues: {
      amount: '0',
      tax: '0',
      day: now.day,
      month: now.month,
      year: now.year,
      fromAccountId: accounts ? accounts[0].id : '',
      toAccountId: accounts ? accounts[0].id : '',
      amountCurrencyCode: 'USD',
      taxCurrencyCode: 'USD',
      comment: '',
    },
    enableReinitialize: true,
    onSubmit: (values) => onCreate(values),
  });

  if (!accounts || !categories) {
    return null;
  }

  return (
    <div>
      <ModalBody>
        <Textarea
          label="Comment"
          name="comment"
          onChange={formik.handleChange}
          value={formik.values.comment}
        />
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 10,
        }}
        >
          <Select
            disallowEmptySelection
            label="From"
            onChange={(e) => {
              formik.setFieldValue('fromAccountId', e.target.value);
            }}
            selectedKeys={[formik.values.fromAccountId]}
          >
            {accounts ? accounts.map((account) => (
              <SelectItem key={account.id} value={account.id}>
                {account.title}
              </SelectItem>
            )) : []}
          </Select>
          <Select
            disallowEmptySelection
            label="To"
            onChange={(e) => {
              formik.setFieldValue('toAccountId', e.target.value);
            }}
            selectedKeys={[formik.values.toAccountId]}
          >
            {accounts ? accounts.map((account) => (
              <SelectItem key={account.id} value={account.id}>
                {account.title}
              </SelectItem>
            )) : []}
          </Select>
        </div>
        <div style={{
          display: 'flex',
          gap: 10,
          marginTop: 20,
        }}
        >
          <Input
            label="Amount"
            name="amount"
            onChange={formik.handleChange}
            value={formik.values.amount}
          />
          <Select
            disallowEmptySelection
            label="Amount currency"
            onChange={(e) => {
              formik.setFieldValue('amountCurrencyCode', e.target.value);
            }}
            selectedKeys={[formik.values.amountCurrencyCode]}
          >
            {currencies.map((currency) => (
              <SelectItem key={currency.id} value={currency.id}>
                {currency.title}
              </SelectItem>
            ))}
          </Select>
        </div>
        <div style={{
          display: 'flex',
          gap: 10,
        }}
        >
          <Input
            label="Tax"
            name="tax"
            onChange={formik.handleChange}
            value={formik.values.tax}
          />
          <Select
            disallowEmptySelection
            label="Tax currency"
            onChange={(e) => {
              formik.setFieldValue('taxCurrencyCode', e.target.value);
            }}
            selectedKeys={[formik.values.taxCurrencyCode]}
          >
            {currencies.map((currency) => (
              <SelectItem key={currency.id} value={currency.id}>
                {currency.title}
              </SelectItem>
            ))}
          </Select>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 10,
          marginTop: 20,
        }}
        >
          <Input
            label="Day"
            name="day"
            onChange={formik.handleChange}
            value={formik.values.day}
          />
          <Input
            label="Month"
            name="month"
            onChange={formik.handleChange}
            value={formik.values.month}
          />
          <Input
            label="Year"
            name="year"
            onChange={formik.handleChange}
            value={formik.values.year}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" variant="light" onPress={onClose}>
          Close
        </Button>
        <Button
          color="primary"
          type="submit"
          onPress={() => {
            formik.submitForm();
            onClose();
          }}
        >
          Add
        </Button>
      </ModalFooter>
    </div>
  );
};

export default CreateTransferForm;
