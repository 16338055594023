import Icon from './Icon';

const DEFAULT_ICON = 'default';

function CategoryIcon({ icon = DEFAULT_ICON, size = '6' }: { icon?: string, size?: string }) {
  const iconKey = (icon || DEFAULT_ICON).charAt(0).toUpperCase() + (icon || DEFAULT_ICON).slice(1);
  const DynamicIcon = Icon[iconKey as keyof typeof Icon];

  return (
    <DynamicIcon
      size={size}
      className={`h-${size} w-${size} text-default-900 dark:text-default-900`}
    />
  );
}

CategoryIcon.defaultProps = {
  icon: DEFAULT_ICON,
  size: '24',
};

export default CategoryIcon;
