import React, { useContext, createContext, useCallback } from 'react';
import api from '../api';

export type HttpFunction = <T>(path: string, config?: any) => Promise<T>;

const HttpContext = createContext<HttpFunction>(null as any);

export const useHttp = () => useContext(HttpContext);

export function HttpProvider({ children }: { children: React.ReactNode }) {
  const http = useCallback(async (path: string, config: any = {}) => {
    const token = localStorage.getItem('token');
    const headers = new Headers(config.headers || {});
    headers.set('Content-Type', 'application/json');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    const requestConfig = {
      ...config,
      headers,
    };

    let response = await fetch(path, requestConfig);

    if (response.status === 401) {
      const refreshToken = localStorage.getItem('refreshToken');
      if (refreshToken) {
        try {
          const refreshRes = await api.user.refreshToken(refreshToken, http);
          localStorage.setItem('refreshToken', refreshRes.tokens.refreshToken);
          localStorage.setItem('token', refreshRes.tokens.jwtToken);

          headers.set('Authorization', `Bearer ${refreshRes.tokens.jwtToken}`);
          response = await fetch(path, requestConfig); // Retry the request with the new token
        } catch (error) {
          console.error('Error during token refresh:', error);
          // Redirect to login or handle the error appropriately
        }
      }
    }

    if (!response.ok) {
      const errObj = await response.json();

      throw errObj;
      // throw new Error(`HTTP Error: ${response.status}`);
    }

    return response.json();
  }, []);

  return (
    <HttpContext.Provider value={http}>
      {children}
    </HttpContext.Provider>
  );
}
