import {
  Button, Card, CardBody, Checkbox, Input, ModalBody, ModalFooter, ModalHeader, Radio, RadioGroup, Select, SelectItem, Textarea,
} from '@nextui-org/react';
import { FC } from 'react';
import { useFormik } from 'formik';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { TagIcon } from 'lucide-react';
import api from '../api';
import { currencies } from '../constants';
import getNowDayMonthYear from '../helpers/getNowDayMonthYear';
import { useHttp } from '../contexts/http';

interface IFormInput {
  type: string;
  amount: string;
  currencyCode: string;
  day: string;
  month: string;
  year: string;
  categoryId: string;
  accountId: string;
  comment: string;
  tags: string[];
}

const EditTransactionForm: FC<{
  onClose: any,
  id: string,
  onDelete: (id: string) => void
  onUpdate: (values: Omit<IFormInput, 'tags'> & { id: string }) => void,
}> = ({
  onClose, id, onDelete, onUpdate,
}) => {
  const http = useHttp();

  const transactionQuery = useQuery({ queryKey: ['transaction.get', id], queryFn: () => api.transaction.get(id, http) });
  const transactionTagsQuery = useQuery({ queryKey: ['transaction-tag.get', id], queryFn: () => api.transactionTag.getByTransaction(id, http) });
  const tagsQuery = useQuery({ queryKey: ['tag.list'], queryFn: () => api.tag.list(http) });

  // create transaction tag mutation
  const tags = tagsQuery.data;

  const transaction = transactionQuery.data || {
    transactionAt: new Date(),
    type: 'expense',
    amount: 0,
    currencyCode: 'USD',
    comment: '',
    categoryId: '',
    accountId: '',
    account: {
      currencyCode: 'USD',
    },
  };

  const date = getNowDayMonthYear(new Date(transaction.transactionAt));

  const initialValues: IFormInput = {
    type: transaction.type,
    amount: String(transaction.amount),
    currencyCode: transaction?.account ? transaction?.account.currencyCode : 'USD', // TODO: Take from account
    // comment: transaction.comment,
    day: date.day,
    month: date.month,
    year: date.year,
    categoryId: transaction.categoryId,
    accountId: transaction.accountId,
    comment: transaction.comment || '',
    tags: transactionTagsQuery.data ? transactionTagsQuery.data.map((tag) => tag.tagId) : [],
  };

  const queryClient = useQueryClient();

  const createTagsMutation = useMutation({
    mutationFn: (values: any) => api.transactionTag.create(values, http),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['transaction.list'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.stats'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.grouped-by-category-list'] });
      // queryClient.invalidateQueries({ queryKey: ['monthly-budget.spend', currentYear, currentMonth] })
      queryClient.invalidateQueries({ queryKey: ['transaction.month-infinite'] });
    },
  });

  const deleteTagMutation = useMutation({
    mutationFn: (transactionTagId: string) => api.transactionTag.delete(transactionTagId, http),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['transaction.list'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.stats'] });
      queryClient.invalidateQueries({ queryKey: ['transaction.grouped-by-category-list'] });
      // queryClient.invalidateQueries({ queryKey: ['monthly-budget.spend', currentYear, currentMonth] })
      queryClient.invalidateQueries({ queryKey: ['transaction.month-infinite'] });
    },
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      const { tags: valuesTags, ...rest } = values;

      // if tags doesn't present in initialValues, it means that we need to create new tags
      const tagsToCreate = valuesTags.filter((tagId) => !initialValues.tags.includes(tagId));

      // if tags doesn't present in values, it means that we need to delete tags
      const tagsToDelete = initialValues.tags.filter((tagId) => !valuesTags.includes(tagId));

      // create tags
      if (tagsToCreate.length) {
        createTagsMutation.mutate({
          transactionId: id,
          tags: tagsToCreate,
        });
      }

      // delete tags
      if (tagsToDelete.length) {
        tagsToDelete.forEach((tagId) => {
          const transactionTag = (transactionTagsQuery.data || []).find((_transactionTag) => _transactionTag.tagId === tagId);
          if (transactionTag) {
            deleteTagMutation.mutate(transactionTag.id);
          }
        });
      }

      // create Tag to create/update/delete
      onUpdate({
        ...rest,
        id,
      });
    },
  });

  const categoriesQuery = useQuery({ queryKey: ['category.list'], queryFn: () => api.category.list(http) });
  const accountsQuery = useQuery({ queryKey: ['account.list'], queryFn: () => api.account.list(http) });

  const categories = categoriesQuery.data;
  const accounts = accountsQuery.data;

  if (!transaction) {
    return null;
  }

  return (
    <div>
      <ModalHeader className="flex flex-col gap-1">Edit transaction</ModalHeader>
      <ModalBody>
        <RadioGroup
          label="Type"
          color="secondary"
          name="type"
          onChange={formik.handleChange}
          value={formik.values.type}
        >
          <Radio color="danger" value="expense">Expense</Radio>
          <Radio color="success" value="income">Income</Radio>
        </RadioGroup>
        <Textarea
          label="Comment"
          name="comment"
          onChange={formik.handleChange}
          value={formik.values.comment}
        />
        <Select
          disallowEmptySelection
          label="Select account"
          onChange={(e) => {
            formik.setFieldValue('accountId', e.target.value);
          }}
          selectedKeys={[formik.values.accountId]}
        >
          {accounts ? accounts.map((account) => (
            <SelectItem key={account.id} value={account.id}>
              {account.title}
            </SelectItem>
          )) : []}
        </Select>
        <Select
          disallowEmptySelection
          label="Select category"
          onChange={(e) => {
            formik.setFieldValue('categoryId', e.target.value);
          }}
          selectedKeys={[formik.values.categoryId]}
        >
          {categories ? categories.map((category) => (
            <SelectItem key={category.id} value={category.id}>
              {category.title}
            </SelectItem>
          )) : []}
        </Select>
        <div style={{
          display: 'flex',
          gap: 10,
        }}
        >
          <Input
            label="Amount"
            name="amount"
            onChange={formik.handleChange}
            value={formik.values.amount}
          />
          <Select
            disallowEmptySelection
            label="Select currency"
            onChange={(e) => {
              formik.setFieldValue('currencyCode', e.target.value);
            }}
            selectedKeys={[formik.values.currencyCode]}
          >
            {currencies.map((currency) => (
              <SelectItem key={currency.id} value={currency.id}>
                {currency.title}
              </SelectItem>
            ))}
          </Select>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 10,
        }}
        >
          <Input
            label="Day"
            name="day"
            onChange={formik.handleChange}
            value={formik.values.day}
          />
          <Input
            label="Month"
            name="month"
            onChange={formik.handleChange}
            value={formik.values.month}
          />
          <Input
            label="Year"
            name="year"
            onChange={formik.handleChange}
            value={formik.values.year}
          />
        </div>
        <div style={{
          marginBottom: 10,
        }}
        >
          <Card>
            <CardBody style={{
              gap: 20,
              flexDirection: 'row',
            }}
            >
              {tags && tags.map((tag) => (
                <Checkbox
                  key={tag.id}
                  icon={<TagIcon />}
                  onChange={(e) => {
                    if (e.target.checked) {
                      formik.setFieldValue('tags', [...formik.values.tags, tag.id]);
                    } else {
                      formik.setFieldValue('tags', formik.values.tags.filter((tagId) => tagId !== tag.id));
                    }
                  }}
                  isSelected={formik.values.tags.includes(tag.id)}
                >
                  {tag.title}
                </Checkbox>
              ))}
            </CardBody>
          </Card>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          variant="light"
          onPress={() => {
            onDelete(id);
            onClose();
          }}
        >
          Delete
        </Button>
        <Button
          variant="light"
          onPress={() => {
            onClose();
          }}
        >
          Close
        </Button>
        <Button
          color="primary"
          type="submit"
          onPress={() => {
            formik.submitForm();
            onClose();
          }}
        >
          Update
        </Button>
      </ModalFooter>
    </div>
  );
};

export default EditTransactionForm;
