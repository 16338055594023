import {
  Button, Card, CardHeader, Tooltip,
} from '@nextui-org/react';
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { TagIcon } from '@heroicons/react/24/solid';
import {
  DownloadIcon, MoveDownRightIcon, MoveUpRightIcon,
  PlusCircleIcon,
  TextIcon,
} from 'lucide-react';
import api from '../api';
import getDate from '../helpers/getDate';
import priceWithCurrency from '../helpers/priceWithCurrency';
import { useHttp } from '../contexts/http';
import CategoryIcon from './CategoryIcon';
import getNowDayMonthYear from '../helpers/getNowDayMonthYear';
import useModals from '../hooks/useModals';

function TransactionsTable({ onOpenEditTransactionModal, currentMonth, currentYear }: {
  onOpenEditTransactionModal: (id: string) => void
  currentMonth: number,
  currentYear: number
}) {
  const http = useHttp();
  const { modalContext } = useModals();

  const queryClient = useQueryClient();

  const query = useInfiniteQuery({
    initialPageParam: null as string | null,
    queryKey: ['transaction.month-infinite'],
    queryFn: (props) => {
      const { pageParam: cursor } = props as unknown as { pageParam: string | null };
      return api.transaction.list({
        http,
        cursor: cursor || null,
        year: currentYear,
        month: currentMonth,
      });
    },
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    // getPreviousPageParam: (firstPage, allPages) => firstPage.prevCursor,
  });

  useEffect(() => {
    query.refetch();
  }, [currentMonth, currentYear]);

  const transactionsAndTransfers = query.data;

  const categoriesQuery = useQuery({ queryKey: ['category.list'], queryFn: () => api.category.list(http) });
  const accountsQuery = useQuery({ queryKey: ['account.list'], queryFn: () => api.account.list(http) });

  const categories = categoriesQuery.data;
  const accounts = accountsQuery.data;

  const transactionsStatsQuery = useQuery({ queryKey: ['transaction.stats'], queryFn: () => api.transaction.stats(http) });
  const transactionsStats = transactionsStatsQuery.data;

  if (
    !transactionsAndTransfers || !categories || !accounts || !transactionsStats
  ) { // TODO: !transactionsStats.dailyExpenses ???
    return null;
  }

  const flat = transactionsAndTransfers.pages.map(({ transactions }: any) => transactions).flat();

  // group flat by date and sort by date
  const grouped = flat.reduce((acc: any, transaction: any) => {
    const date = getDate(transaction.transactionAt);
    if (!acc[date]) {
      acc[date] = [];
    }

    acc[date].push(transaction);
    return acc;
  }, {});

  const groupedArr = Object.keys(grouped).map((key) => ({
    date: key,
    transactions: grouped[key],
  })).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  if (!groupedArr.length) {
    return (
      <Button
        variant="ghost"
        className="flex m-auto"
        onPress={() => {
          modalContext.addExpenseDisclosure.onOpen();
        }}
      >
        <PlusCircleIcon size={20} />
        {' '}
        Add first transaction
      </Button>
    );
  }

  return (
    <div>
      <div>
        {groupedArr.map((group) => (
          <Card key={group.date} className="mb-6 bg-transparent">
            <CardHeader className="bg-content2 rounded-xl pl-6 pr-4 flex justify-between">
              <p className="tracking-wider font-light text-sm lg:text-md">{group.date}</p>
              <Button
                variant="bordered"
                size="sm"
                onPress={() => {
                  const { day, month, year } = getNowDayMonthYear(new Date(group.date));

                  queryClient.setQueryData(['localCache.createExpenseValues'], {
                    day,
                    month: String(Number(month)),
                    year,
                  });

                  modalContext.addTransactionDisclosure.onOpen();
                }}
              >
                Add expense
              </Button>
            </CardHeader>
            <div className="pt-4 flex gap-2 flex-col">
              {group.transactions.map((transaction: any) => (
                <div
                  key={transaction.id}
                  className="flex flex-row gap-6"
                  onClick={() => {
                    onOpenEditTransactionModal(transaction.id);
                  }}
                >
                  <div className="bg-content1 p-4 rounded-xl w-full flex flex-row hidden lg:flex items-center">
                    {
                      transaction.type === 'income' ? <MoveUpRightIcon width={50} color="#555555" /> : <MoveDownRightIcon width={50} color="#FDBD1A" />
                    }
                    <div className="flex justify-between w-full ml-4">
                      <p className="text-lg font-bold tracking-wider pr-2">
                        {priceWithCurrency(transaction.usdAmount)}
                      </p>
                      <div className="flex gap-4">
                        <div className="flex gap-4">
                          <p className="tracking-wider">
                            <span className="text-default-200">{transaction.type === 'expense' ? 'from' : 'to'}</span>
                            {' '}
                            <span className="text-lg">
                              {accounts.find((account) => account.id === transaction.accountId)?.title || 'N/A'}
                            </span>
                          </p>
                          <p className="tracking-wider flex flex-row gap-2 items-center">
                            <span className="text-default-200">{transaction.type === 'expense' ? 'in' : 'by'}</span>
                            {' '}
                            <span className="text-lg flex flex-row gap-2 items-center">
                              <CategoryIcon icon={categories.find((category) => category.id === transaction.categoryId)?.icon} size="5" />
                              <span>
                                {categories.find((category) => category.id === transaction.categoryId)?.title || 'N/A'}
                              </span>
                            </span>
                          </p>
                        </div>
                        {transaction.comment && (
                        <p className="flex">
                          <TextIcon width={50} color="#555555" />
                          {transaction.comment}
                        </p>
                        )}
                        <p className="tracking-wider">
                          {transaction.transactionTags.map((transactionTag: any, index: any) => (
                            <span key={index} className="mr-2">
                              <Tooltip content={transactionTag?.tag?.title} className="capitalize">
                                <TagIcon className="h-4 w-4 inline" color={`#${transactionTag?.tag?.color}`} />
                              </Tooltip>
                            </span>
                          ))}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex lg:hidden w-full flex-row gap-4 bg-background p-4 rounded-xl w-full bg-content1 flex-row justify-between">
                    <p className="text-xs font-bold tracking-wider pr-2 flex flex-col gap-2 items-start">
                      {
                        transaction.type === 'income' ? <MoveUpRightIcon color="#555555" /> : <MoveDownRightIcon color="#FDBD1A" />
                      }
                      {priceWithCurrency(transaction.usdAmount)}
                    </p>
                    <div className="flex flex-row gap-4">
                      <p className="tracking-wider flex flex-col justify-between">
                        <span className="text-default-200 text-xs">{transaction.type === 'expense' ? 'from' : 'to'}</span>
                        <span className="text-xs">
                          {accounts.find((account) => account.id === transaction.accountId)?.title || 'N/A'}
                        </span>
                      </p>
                      <p className="tracking-wider gap-2 flex-col flex items-start justify-between">
                        <span className="text-default-200 text-xs">{transaction.type === 'expense' ? 'in' : 'by'}</span>
                        {' '}
                        <span className="text-lg flex flex-row gap-2 items-center">
                          <CategoryIcon icon={categories.find((category) => category.id === transaction.categoryId)?.icon} size="5" />
                          <span className="ml-1 text-xs">
                            {categories.find((category) => category.id === transaction.categoryId)?.title || 'N/A'}
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}

            </div>
          </Card>
        ))}
      </div>
      {
        query.hasNextPage && (
          <Button
            variant="bordered"
            onClick={() => {
              query.fetchNextPage();
            }}
            className="tracking-wider font-light text-sm"
          >
            <DownloadIcon />
            {' '}
            Load more
          </Button>
        )
      }
    </div>
  );
}

export default TransactionsTable;
