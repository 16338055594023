import {
  Button, Input, ModalBody, ModalFooter, ModalHeader,
} from '@nextui-org/react';
import { FC } from 'react';
import { useFormik } from 'formik';
import { CirclePicker } from 'react-color';
import { Tag } from '../types';

interface IFormInput {
  title: string,
  color: string,
  id: string
}

const EditTagForm: FC<{
  onClose: any,
  onUpdate: (values: IFormInput) => void,
  onDelete: (id: string) => void,
  tag: Tag
}> = ({
  onClose, onUpdate, tag, onDelete,
}) => {
  const formik = useFormik({
    initialValues: {
      title: tag.title,
      color: tag.color,
    },
    enableReinitialize: true,
    onSubmit: (values) => onUpdate({
      ...values,
      id: tag.id,
    }),
  });

  return (
    <div>
      <ModalHeader className="flex flex-col gap-1">Add tag</ModalHeader>
      <ModalBody>
        <Input
          label="Title"
          name="title"
          onChange={formik.handleChange}
          value={formik.values.title}
        />
        <div style={{
          margin: '15px auto 10px',
        }}
        >
          <CirclePicker
            color={formik.values.color}
            onChangeComplete={(color) => formik.setFieldValue('color', color.hex.slice(1))}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          variant="light"
          onPress={() => {
            onDelete(tag.id);
            onClose();
          }}
        >
          Delete
        </Button>
        <Button
          variant="light"
          onPress={() => {
            onClose();
          }}
        >
          Close
        </Button>
        <Button
          color="primary"
          type="submit"
          onPress={() => {
            formik.submitForm();
            onClose();
          }}
        >
          Update
        </Button>
      </ModalFooter>
    </div>
  );
};

export default EditTagForm;
