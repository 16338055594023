import { HOST } from '../constants';
import { HttpFunction } from '../contexts/http';
import {
  Account, AccountBalance, Category, Transaction, TransactionByCategory, TransactionStats, Transfer, TransactionListResponse, RecurrentBudget, GetExpensesByMonthRes, Tag,
} from '../types';

// accounts

const listAccounts = async (http: HttpFunction) => http<Account[]>(`${HOST}/accounts`);

const getAccount = async (id: string, http: HttpFunction) => http<Account>(`${HOST}/accounts/${id}`);

export type CreateAccountParams = {
  title: string,
  type: string,
  initialBalance: string,
  currencyCode: string,
  description: string
  color: string
}

const createAccount = async ({
  title, type, initialBalance, description, currencyCode, color,
}: CreateAccountParams, http: HttpFunction) => {
  const requestBody = {
    title,
    type,
    initialBalance,
    description,
    currencyCode,
    color: color.slice(1),
  };

  return http<Account>(`${HOST}/accounts`, {
    method: 'post',
    body: JSON.stringify(requestBody),
  });
};

const updateAccount = async (data: {
  id: string,
  title: string,
  initialBalance: string,
  description: string
}, http: HttpFunction) => {
  const { id, ...rest } = data;

  const requestBody = {
    ...rest,
  };

  return http<Account>(`${HOST}/accounts/${id}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json', // Specify the content type as JSON
    },
    body: JSON.stringify(requestBody),
  });
};

const deleteAccount = async (id: string, http: HttpFunction) => http<Account>(`${HOST}/accounts/${id}`, {
  method: 'delete',
});

// account balances

const createAccountBalance = async (data: {
  accounts: {
    id: string,
    balance: string,
  }[],
  confirmedAt: string
}, http: HttpFunction) => {
  const requestBody = {
    ...data,
  };

  return http<AccountBalance>(`${HOST}/account-balances`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json', // Specify the content type as JSON
    },
    body: JSON.stringify(requestBody),
  });
};

const listAccountBalances = async (cursor: string | null, http: HttpFunction) => http<{
    data: AccountBalance[],
    nextCursor: string | undefined
  }>(`${HOST}/account-balances${cursor ? `?cursor=${cursor}` : ''}`);

const getLastAccountBalance = async (http: HttpFunction) => http<any[]>(`${HOST}/account-balances/last`);

const getTotalAccountBalance = async (http: HttpFunction) => http<any[]>(`${HOST}/account-balances/total`);

// categories

const listCategories = async (http: HttpFunction) => http<Category[]>(`${HOST}/categories`);

const getCategoriesExpenseStats = async (http: HttpFunction, params?: {
  month: number,
  year: number
}) => http<Transaction[]>(`${HOST}/categories/stats/expenses${params ? `?month=${params.month}&year=${params.year}` : ''}`);

const getCategoriesIncomeStats = async (http: HttpFunction, params?: {
  month: number,
  year: number
}) => http<Transaction[]>(`${HOST}/categories/stats/incomes${params ? `?month=${params.month}&year=${params.year}` : ''}`);

const getCategory = async (id: string, http: HttpFunction) => http<Category>(`${HOST}/categories/${id}`);

const createCategory = async ({
  title, description, type, disabled,
}: {
  title: string,
  description: string
  type: string
  disabled: boolean
}, http: HttpFunction) => {
  const requestBody = {
    title,
    description,
    type,
    disabled,
  };

  return http<Category>(`${HOST}/categories`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json', // Specify the content type as JSON
    },
    body: JSON.stringify(requestBody),
  });
};

const updateCategory = async (data: {
  id: string,
  title: string,
  description: string
  type: string
  disabled: boolean
  icon: string
}, http: HttpFunction) => {
  const {
    id, title, description, type, disabled, icon,
  } = data;

  const requestBody = {
    title,
    description,
    type,
    disabled,
    icon,
  };

  return http<Category>(`${HOST}/categories/${id}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json', // Specify the content type as JSON
    },
    body: JSON.stringify(requestBody),
  });
};

const deleteCategory = async (id: string, http: HttpFunction) => http<Category>(`${HOST}/categories/${id}`, {
  method: 'delete',
});

const getCategoryExpensesByMonth = async (id: string, http: HttpFunction) => http<any>(`${HOST}/categories/${id}/expenses-by-month`);

// transactions
const listTransactions = async ({
  http, cursor, year, month, categoryId,
}: {
  http: HttpFunction,
  cursor: string | null,
  year?: number,
  month?: number,
  categoryId?: string
}) => {
  const query = cursor ? `cursor=${cursor}` : '';

  const yearAndMonthParams = (year && month) ? `year=${year}&month=${month}&` : '';

  return http<TransactionListResponse>(`${HOST}/transactions?${yearAndMonthParams}${query}${categoryId ? `categoryId=${categoryId}` : ''}`);
};

const getTransaction = async (id: string, http: HttpFunction) => http<Transaction>(`${HOST}/transactions/${id}`);

type CreateTransactionProps = {
  type: string;
  amount: string;
  comment: string;
  day: string;
  month: string;
  year: string;
  categoryId: string;
  accountId: string;
  currencyCode: string;
}

const createTransaction = async ({
  type, amount, comment, year, month, day, categoryId, accountId, currencyCode,
}: CreateTransactionProps, http: HttpFunction) => {
  const requestBody = {
    type,
    amount,
    comment,
    transactionAt: `${year}-${month}-${day}`,
    categoryId,
    accountId,
    currencyCode,
  };

  return http<Transaction>(`${HOST}/transactions`, {
    method: 'post',
    body: JSON.stringify(requestBody),
  });
};

type CreateExpensesProps = {
  type: string;
  amount: string;
  comment: string;
  day: string;
  month: string;
  year: string;
  categoryId: string;
  accountId: string;
  currencyCode: string;
  splittedCategories: {
    categoryId: string;
    amount: string;
  }[]
}

const createExpenses = async ({
  type, amount, comment, year, month, day, categoryId, accountId, currencyCode, splittedCategories,
}: CreateExpensesProps, http: HttpFunction) => {
  const requestBody = {
    type,
    amount,
    comment,
    transactionAt: `${year}-${month}-${day}`,
    categoryId,
    accountId,
    currencyCode,
    splittedCategories,
  };

  return http<Transaction[]>(`${HOST}/transactions/expenses`, {
    method: 'post',
    body: JSON.stringify(requestBody),
  });
};

const updateTransaction = async (data: {
  id: string;
  type: string;
  amount: string;
  currencyCode: string;
  day: string;
  month: string;
  year: string;
  categoryId: string;
  accountId: string;
  comment: string;
}, http: HttpFunction) => {
  const {
    id, type, amount, currencyCode, day, month, year, categoryId, accountId, comment,
  } = data;

  const requestBody = {
    type,
    amount,
    currencyCode,
    transactionAt: `${year}-${month}-${day}`,
    categoryId,
    accountId,
    comment,
  };

  return http<Transaction>(`${HOST}/transactions/${id}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json', // Specify the content type as JSON
    },
    body: JSON.stringify(requestBody),
  });
};

const deleteTransaction = async (id: string, http: HttpFunction) => http<Transaction>(`${HOST}/transactions/${id}`, {
  method: 'delete',
});

const getTransactionsStats = async (http: HttpFunction) => http<TransactionStats>(`${HOST}/transactions/stats`, {
  method: 'get',
});

const listTransactionsGroupedByCategory = async (http: HttpFunction) => http<TransactionByCategory[]>(`${HOST}/transactions?group-by=category`);

const getExpensesByMonth = async (month: number, year: number, http: HttpFunction) => http<GetExpensesByMonthRes>(`${HOST}/transactions/expenses?year=${year}&month=${month}&group-by=category`);

const getExpensesByMonthByTag = async (month: number, year: number, http: HttpFunction) => http<any>(`${HOST}/transactions/expenses/tags?year=${year}&month=${month}`);

// users
const login = async ({ username, password }: {
  username: string;
  password: string;
}, http: HttpFunction) => http<any>(`${HOST}/login`, {
  method: 'post',
  body: JSON.stringify({
    username,
    password,
  }),
});

const signup = async ({ username, password }: {
  username: string;
  password: string;
}, http: HttpFunction) => http<any>(`${HOST}/signup`, {
  method: 'post',
  body: JSON.stringify({
    username,
    password,
  }),
});

const me = async (token: string, http: HttpFunction) => http<any>(`${HOST}/me`, {
  headers: {
    authorization: `Bearer ${token}`,
  },
});

const refreshToken = async (token: string, http: HttpFunction) => {
  const res = await http<any>(`${HOST}/refresh-token`, {
    method: 'post',
    body: JSON.stringify({
      refreshToken: token,
    }),
  });

  return res;
};

const updateTheme = async (theme: string, http: HttpFunction) => http<any>(`${HOST}/users/theme`, {
  method: 'post',
  body: JSON.stringify({
    theme,
  }),
});

const updateSettings = async (settings: {
  totalBalanceAccountType: string
}, http: HttpFunction) => http<any>(`${HOST}/users/theme`, {
  method: 'post',
  body: JSON.stringify({
    settings,
  }),
});

// transfer
type CreateTransferProps = {
  tax: string;
  amount: string;
  comment: string;
  day: string;
  month: string;
  year: string;
  fromAccountId: string;
  toAccountId: string;
  amountCurrencyCode: string;
  taxCurrencyCode: string;
}

const createTransfer = async ({
  tax, amount, comment, year, month, day, fromAccountId, toAccountId, amountCurrencyCode, taxCurrencyCode,
}: CreateTransferProps, http: HttpFunction) => {
  const requestBody = {
    amount,
    tax,
    comment,
    transactionAt: `${year}-${month}-${day}`,
    fromAccountId,
    toAccountId,
    amountCurrencyCode,
    taxCurrencyCode,
  };

  return http<Transaction>(`${HOST}/transfers`, {
    method: 'post',
    body: JSON.stringify(requestBody),
  });
};

type ListTransferRes = {
  transfers: Transfer[],
}

const listTransfers = async ({
  year, month,
}: {
  year: number, month: number
}, http: HttpFunction) => http<ListTransferRes>(`${HOST}/transfers?year=${year}&month=${month}`);

const updateTransfer = async (data: {
  id: string;
  amount: string;
  tax: string;
  amountCurrencyCode: string;
  taxCurrencyCode: string;
  day: string;
  month: string;
  year: string;
  fromAccountId: string;
  toAccountId: string;
  comment: string;
}, http: HttpFunction) => {
  const {
    id, amount, tax, amountCurrencyCode, taxCurrencyCode, day, month, year, fromAccountId, toAccountId, comment,
  } = data;

  const requestBody = {
    amount,
    tax,
    amountCurrencyCode,
    taxCurrencyCode,
    transactionAt: `${year}-${month}-${day}`,
    fromAccountId,
    toAccountId,
    comment,
  };

  return http<Transfer>(`${HOST}/transfers/${id}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json', // Specify the content type as JSON
    },
    body: JSON.stringify(requestBody),
  });
};

const deleteTransfer = async (id: string, http: HttpFunction) => http<Transfer>(`${HOST}/transfers/${id}`, {
  method: 'delete',
});

// budget

const createBudget = async (data: {
  monthlyBudget: string;
  monthlyBudgetCurrency: string;
  yearlyBudget: string;
  yearlyBudgetCurrency: string;
}, http: HttpFunction) => {
  const requestBody = {
    ...data,
  };

  return http<RecurrentBudget>(`${HOST}/recurrent-budget`, {
    method: 'post',
    body: JSON.stringify(requestBody),
  });
};

const getBudgets = async (http: HttpFunction) => http<RecurrentBudget[]>(`${HOST}/recurrent-budget`, {
  method: 'get',
});

// recurrent budget confirm
const createRecurrentBudgetConfirm = async (data: {
  month: number, year: number
}, http: HttpFunction) => {
  const requestBody = {
    ...data,
  };

  return http<RecurrentBudget>(`${HOST}/recurrent-budget-confirm`, {
    method: 'post',
    body: JSON.stringify(requestBody),
  });
};

const getRecurrentBudgetConfirm = async (year: number, month: number, http: HttpFunction) => http<RecurrentBudget>(`${HOST}/recurrent-budget-confirm?year=${year}&month=${month}`, {
  method: 'get',
});

// tag
const listTags = async (http: HttpFunction) => http<Tag[]>(`${HOST}/tags`);

const createTag = async (data: {
  title: string;
  color: string;
}, http: HttpFunction) => {
  const requestBody = {
    ...data,
  };

  return http<Tag>(`${HOST}/tags`, {
    method: 'post',
    body: JSON.stringify(requestBody),
  });
};

const updateTag = async (data: {
  id: string;
  title: string;
  color: string;
  disabled: boolean;
}, http: HttpFunction) => {
  const {
    id, title, color, disabled,
  } = data;

  const requestBody = {
    title,
    color,
    disabled,
  };

  return http<Tag>(`${HOST}/tags/${id}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json', // Specify the content type as JSON
    },
    body: JSON.stringify(requestBody),
  });
};

const deleteTag = async (id: string, http: HttpFunction) => http<Tag>(`${HOST}/tags/${id}`, {
  method: 'delete',
});

// budget tag
const listRecurrentBudgetTags = async (http: HttpFunction) => http<any>(`${HOST}/recurrent-budget-tags`);

const createRecurrentBudgetTag = async (data: {
  tagId: string;
  limit: string;
  currency: string;
}[], http: HttpFunction) => {
  const requestBody = {
    ...data,
  };

  return http<Tag[]>(`${HOST}/recurrent-budget-tags`, {
    method: 'post',
    body: JSON.stringify(requestBody),
  });
};

// transaction tag
const createTransactionTags = async (data: {
  transactionId: string;
  tags: string[];
}, http: HttpFunction) => {
  const requestBody = {
    ...data,
  };

  return http<Tag>(`${HOST}/transaction-tags`, {
    method: 'post',
    body: JSON.stringify(requestBody),
  });
};

const getTagsByTransaction = async (id: string, http: HttpFunction) => http<any[]>(`${HOST}/transaction-tags/transaction/${id}`);

const deleteTransactionTag = async (id: string, http: HttpFunction) => http<Tag>(`${HOST}/transaction-tags/${id}`, {
  method: 'delete',
});

// computed account balance
const getComputedAccountBalance = async (http: HttpFunction) => http<any>(`${HOST}/computed-account-balances?month=01&year=2024`);

const api = {
  user: {
    login,
    signup,
    me,
    refreshToken,
    updateTheme,
    updateSettings,
  },
  account: {
    list: listAccounts,
    create: createAccount,
    get: getAccount,
    update: updateAccount,
    delete: deleteAccount,
  },
  accountBalance: {
    create: createAccountBalance,
    getLast: getLastAccountBalance,
    getTotal: getTotalAccountBalance,
    list: listAccountBalances,
  },
  category: {
    list: listCategories,
    listExpenses: getCategoriesExpenseStats,
    listIncomes: getCategoriesIncomeStats,
    create: createCategory,
    get: getCategory,
    update: updateCategory,
    delete: deleteCategory,
    expensesByMonth: getCategoryExpensesByMonth,
  },
  transaction: {
    list: listTransactions,
    create: createTransaction,
    get: getTransaction,
    update: updateTransaction,
    delete: deleteTransaction,
    stats: getTransactionsStats,
    listGroupedByCategory: listTransactionsGroupedByCategory,
    getExpensesByMonth,
    getExpensesByMonthByTag,
    createExpenses,
  },
  transfer: {
    create: createTransfer,
    list: listTransfers,
    update: updateTransfer,
    delete: deleteTransfer,
  },
  budget: {
    create: createBudget,
    get: getBudgets,
  },
  recurrentBudgetConfirm: {
    create: createRecurrentBudgetConfirm,
    get: getRecurrentBudgetConfirm,
  },
  tag: {
    list: listTags,
    create: createTag,
    update: updateTag,
    delete: deleteTag,
  },
  transactionTag: {
    create: createTransactionTags,
    getByTransaction: getTagsByTransaction,
    delete: deleteTransactionTag,
  },
  recurrentBudgetTag: {
    list: listRecurrentBudgetTags,
    create: createRecurrentBudgetTag,
  },
  computedAccountBalance: {
    get: getComputedAccountBalance,
  },
};

export default api;
