import { FC } from 'react';

const UITitle: FC<{
  children: string
  className?: string
}> = ({ children, className }) => (
  <p className={`font-bold text-2xl tracking-wide text-default uppercase ${className ? ` ${className}` : ''}`}>
    {children}
  </p>
);

UITitle.defaultProps = {
  className: '',
};

export default UITitle;
