import { Select, SelectItem } from '@nextui-org/react';
// import { useMutation } from "@tanstack/react-query";
// import { useHttp } from "../contexts/http";
// import api from "../api";
import { FC } from 'react';

const DashboardTitleSettingsForm: FC<{
  totalBalanceAccountType: string,
  setTotalBalanceAccountType: (type: 'all' | 'internal') => void,
}> = ({
  totalBalanceAccountType,
  setTotalBalanceAccountType,
}) => (
  <Select
    disallowEmptySelection
    size="sm"
    style={{
      width: 200,
    }}
    label="Total balance"
    onChange={(e) => {
      // formik.setFieldValue('currencyCode', e.target.value)
      setTotalBalanceAccountType(e.target.value as 'all' | 'internal');
    }}
    selectedKeys={[totalBalanceAccountType || 'all']}
  >
    <SelectItem key="all" value="all">
      Accounts and external
    </SelectItem>
    <SelectItem key="internal" value="internal">
      Accounts only
    </SelectItem>
  </Select>
);

export default DashboardTitleSettingsForm;
