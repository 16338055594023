import { ResponsiveLine } from '@nivo/line';

const MyLineChart = ({ data }: {
  data: any
}) => (

  <ResponsiveLine
    margin={{
      top: 10, right: 50, left: 60, bottom: 50,
    }}
    data={[
      {
        id: 'Balance',
        color: 'hsl(252, 70%, 50%)',
        data: [...data],
      },
    ]}
    xScale={{ type: 'point' }}
    yScale={{
      type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false,
    }}
    curve="natural"
    enableArea={false}
    areaOpacity={0.7}
    enablePoints
    pointSize={10}
    pointBorderWidth={2}
    gridXValues={[]}
    // gridYValues={[]}
    theme={{
      grid: {
        line: {
          stroke: '#333', // Customize grid line color here
          strokeWidth: 1, // Optional: Customize grid line width here
        },
      },
      axis: {
        ticks: {
          line: {
            stroke: '#333', // Customize axis tick line color here
          },
          text: {
            fill: '#333', // Customize axis tick text color here
          },
        },
      },
      crosshair: {
        line: {
          stroke: '#333', // Customize crosshair line color here
        },
      },
      tooltip: {
        container: {
          background: '#333', // Customize tooltip container background color here
        },
      },
      legends: {
        text: {
          fill: '#333', // Customize legend text color here
        },
      },
    }}
  />
);

export default MyLineChart;
