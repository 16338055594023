import {
  Button, Input, ModalBody, ModalFooter, ModalHeader, Select, SelectItem,
} from '@nextui-org/react';
import { FC } from 'react';
import { useFormik } from 'formik';
import { useQuery } from '@tanstack/react-query';
import api from '../api';
import { useHttp } from '../contexts/http';
import { currencies } from '../constants';

interface IFormInput {
  monthlyBudget: string;
  monthlyBudgetCurrency: string;
  yearlyBudget: string;
  yearlyBudgetCurrency: string;
}

const CreateBudgetForm: FC<{
  onClose: any,
  onCreate: (values: IFormInput) => void
}> = ({ onClose, onCreate }) => {
  const http = useHttp();
  const query = useQuery({ queryKey: ['recurrent-budget.get'], queryFn: () => api.budget.get(http) });

  const monthlyBudget = String(query?.data?.find((budget) => budget.period === 'monthly')?.usdBudget || '0');
  const yearlyBudget = String(query?.data?.find((budget) => budget.period === 'yearly')?.usdBudget || '0');

  const formik = useFormik({
    initialValues: {
      monthlyBudget,
      monthlyBudgetCurrency: 'USD',
      yearlyBudget,
      yearlyBudgetCurrency: 'USD',
    },
    enableReinitialize: true,
    onSubmit: (values) => onCreate(values),
  });

  return (
    <div>
      <ModalHeader className="flex flex-col gap-1">Add budget</ModalHeader>
      <ModalBody>
        <div style={{
          fontWeight: 'bold',
        }}
        >
          Monthly budget
        </div>
        <div style={{
          display: 'flex',
          gap: 10,
        }}
        >
          <Input
            label="Budget"
            name="monthlyBudget"
            onChange={formik.handleChange}
            value={formik.values.monthlyBudget}
          />
          <Select
            disallowEmptySelection
            label="Tax currency"
            onChange={(e) => {
              formik.setFieldValue('monthlyBudgetCurrency', e.target.value);
            }}
            selectedKeys={[formik.values.monthlyBudgetCurrency]}
          >
            {currencies.map((currency) => (
              <SelectItem key={currency.id} value={currency.id}>
                {currency.title}
              </SelectItem>
            ))}
          </Select>
        </div>
        <div style={{
          marginTop: 20,
          fontWeight: 'bold',
        }}
        >
          Yearly budget
        </div>
        <div style={{
          display: 'flex',
          gap: 10,
        }}
        >
          <Input
            label="Budget"
            name="yearlyBudget"
            onChange={formik.handleChange}
            value={formik.values.yearlyBudget}
          />
          <Select
            disallowEmptySelection
            label="Tax currency"
            onChange={(e) => {
              formik.setFieldValue('yearlyBudgetCurrency', e.target.value);
            }}
            selectedKeys={[formik.values.yearlyBudgetCurrency]}
          >
            {currencies.map((currency) => (
              <SelectItem key={currency.id} value={currency.id}>
                {currency.title}
              </SelectItem>
            ))}
          </Select>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" variant="light" onPress={onClose}>
          Close
        </Button>
        <Button
          color="primary"
          type="submit"
          onPress={() => {
            formik.submitForm();
            onClose();
          }}
        >
          Add
        </Button>
      </ModalFooter>
    </div>
  );
};

export default CreateBudgetForm;
