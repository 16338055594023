import {
  Button, Card, CardBody, CardFooter, CardHeader,
} from '@nextui-org/react';

function daysSinceJanFirst2024() {
  const startDate: any = new Date('2024-01-01');
  const currentDate: any = new Date();

  // Calculate the difference in milliseconds
  const diffInMs = currentDate - startDate;

  // Convert milliseconds to days
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  return diffInDays + 1;
}

function HeroWidget() {
  const renderAbout = () => (
    <Card className="mb-4">
      <CardHeader className="justify-between">
        <div className="flex gap-5">
          {/* <Avatar isBordered radius="full" size="md" src="/avatars/avatar-1.png" /> */}
          <div className="flex flex-col gap-1 items-start justify-center tracking-wider">
            <h4 className="text-small tracking-wider font-bold leading-none text-default-600">Budget Tracker</h4>
            <h5 className="text-small tracking-wider text-default-400 text-content4">version 0.0.1</h5>
          </div>
        </div>
        <Button
          className="bg-transparent text-foreground border-default-200 tracking-wider uppercase"
          color="primary"
          radius="full"
          size="sm"
          variant="bordered"
        >
          Stay updated
        </Button>
      </CardHeader>
      <CardBody className="px-3 py-0 text-small">
        <p className="tracking-light text-sm">
          Hi there! This app is still in development. If you have any feedback or suggestions, please feel free to reach out to me on
          {' '}
          <a
            className="underline"
            href="https://twitter.com/socaseinpoint"
            target="_blank"
            rel="noreferrer"
          >
            Twitter
          </a>
          .
        </p>
        {/* <span className="pt-2">
          #FrontendWithZoey
          <span className="py-2" aria-label="computer" role="img">
            💻
          </span>
        </span> */}
      </CardBody>
      <CardFooter className="gap-3">
        <div className="flex gap-1">
          <p className="font-semibold text-default-400 text-small font-bold">{daysSinceJanFirst2024()}</p>
          <p className="text-default-400 text-small tracking-wider font-light text-content4">Days of development</p>
        </div>
      </CardFooter>
    </Card>
  );

  return (
    <div>
      {renderAbout()}
    </div>
  );
}

export default HeroWidget;
