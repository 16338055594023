import { Analytics } from '@vercel/analytics/react';

import './App.css';
import { NextUIProvider } from '@nextui-org/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { messages as enMessages } from './locales/en/messages';
import { messages as esMessages } from './locales/es/messages';
import { messages as ruMessages } from './locales/ru/messages';

import { HttpProvider } from './contexts/http';
import Index from './pages';
import CategoryPage from './pages/CategoryPage';

import BudgetsPage from './pages/BudgetsPage';
import MonthlyBudgetPage from './pages/MonthlyBudgetPage';
import RoadmapPage from './pages/RoadmapPage';
import CategoriesTagsPage from './pages/CategoriesTagsPage';
import AccountsBallancePage from './pages/AccountsPage';
import ReportsPage from './pages/ReportsPage';
import ProfilePage from './pages/ProfilePage';
import AuthTheme from './hocs/AuthTheme';

import Modals from './components/Modals';

import { NODE_ENV } from './constants';

i18n.load({
  en: enMessages,
  es: esMessages,
  ru: ruMessages,
});

i18n.activate('en');

const IS_DEV = NODE_ENV === 'development';

const router = createBrowserRouter([
  {
    path: '/',
    Component: Index,
  },
  {
    path: '/category/:id',
    Component: CategoryPage,
  },
  {
    path: '/budgets',
    Component: BudgetsPage,
  },
  {
    path: '/monthly-budget',
    Component: MonthlyBudgetPage,
  },
  {
    path: '/roadmap',
    Component: RoadmapPage,
  },
  {
    path: '/categories-tags',
    Component: CategoriesTagsPage,
  },
  {
    path: '/accounts-ballance',
    Component: AccountsBallancePage,
  },
  {
    path: '/reports',
    Component: ReportsPage,
  },
  {
    path: '/profile',
    Component: ProfilePage,
  },
]);

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <AuthTheme>
        <NextUIProvider>
          <I18nProvider i18n={i18n}>
            <HttpProvider>
              <Modals>
                <RouterProvider router={router} />
              </Modals>
            </HttpProvider>
            {!IS_DEV && <Analytics />}
          </I18nProvider>
        </NextUIProvider>
      </AuthTheme>
    </QueryClientProvider>
  );
}

export default App;
